import React, {useEffect, useState} from 'react';
import {GetUser} from "../api";

const UserAdmin = () => {
    const [data, setData] = useState([])
    useEffect(() => {
        GetUser('').then((response) => {
            console.log(response)
            setData(response.data)
        })
    }, [])

    return (
        <div className={'UserAdmin'}>
            <p className={'blue-color-2'}> {!!data && data.length} یوزر </p>
            {!!data && data.length > 0 &&
            data.map((v, i) => {
                return <div key={i} className="showUser">
    <span>
        {v.firstName + ' ' + v.lastName}
    </span>
                    <span>
                            {v.birthdate}
                        </span>
                    <span>
                            {v.phoneNumber}
                        </span>
                    <span>
                            {v.created}
                        </span>
                    <span>
                            {!!v.nationalId ? v.nationalId : 'فاقد کدملی'}
                        </span>
                </div>
            })
            }
            <div className="line-space"/>
            {/*<p className={'blue-color-2'}>{!!data && data.filter(e => !e.nationalIdConfirmed).length}  نفر  احراز نشده (بدون کدملی)</p>*/}
           {/* {!!data && data.length > 0 &&
            data.filter(e => !e.nationalIdConfirmed).map((v, i) => {
                return <div key={i} className="showUser">
    <span>
        {v.firstName + ' ' + v.lastName}
    </span>
                    <span>
                            {v.birthdate}
                        </span>
                    <span>
                            {v.phoneNumber}
                        </span>
                    <span>
                            {v.created}
                        </span>
                    <span>
                            {!!v.nationalId ? v.nationalId : 'فاقد کدملی'}
                        </span>
                </div>
            })
            }*/}
        </div>
    );
};

export default UserAdmin;

import React from 'react';

export const Lines = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.46 7.43">
                <defs>
                    {/*<style>.cls-1{fill:none;stroke:#080999;stroke-linecap:round;stroke-linejoin:round;stroke-width:0.25px;}</style>*/}
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="cls-1" style={{fill:'none',stroke:'#DAAE5A ',strokeLinecap:'round',strokeLinejoin:'round',strokeWidth:0.25}}
                              d="M74.33,7.31h-51a6.6,6.6,0,0,1-4.68-1.95l-3.3-3.29A6.61,6.61,0,0,0,10.63.13H.13"/>
                    </g>
                </g>
            </svg>
        </>
    );
};


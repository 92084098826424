import React from 'react';

const ErrorAlert = ({text}) => {
    return (
        <>
            {
                text.length > 0 &&
                <div className='ErrorAlert'>
                    {text}
                </div>

            }
        </>

    );
};

export default ErrorAlert;

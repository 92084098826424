import {toast} from "react-toastify";
// export const URL = 'http://192.168.1.100/chineh/';
export const URL = 'https://chineh.ir/chinehapi/';
export const RepresentativeId = 'e39c16a7-767d-4abf-a8ed-3f20c1a95fb7';
export const Version = 'v1/'
export const iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
export const Separator = (x) => {
    return !!x ? x.toLocaleString('en-US') : 0;
}
const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];

export const FixNumbers = (str) => {
    if (typeof str === 'string') {
        for (let i = 0; i < 10; i++) {
            str = str.replace(persianNumbers[i], i);
        }
    }
    return str;
};

function getChromeVersion () {
    let raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
}

console.log(getChromeVersion(), 'chrome')
const errors = (e) => toast.error(e);

function getOS() {
    let userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    } else {
        os = 'nothing';
    }

    return os;
};
export const Platform = getOS();

const HandelError = (response) => {
    if (response.status !== 200) {
        errors('مشکل در ارتباط با سرور')
        // return {response: {status: 500}}
    }
}

export const LoginApi = (body) => {
    return fetch(URL + 'api/v1/accounts/signin', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors'
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            return response.json();
        })
};

export const CheckToken = () => {
    return fetch(URL + 'api/v1/accounts/getme', {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    }).then(async (response) => {
        HandelError(response)
        return response.json();
    })
};

export const RegisterApi = (body) => {
    return fetch(URL + 'api/v1/customers', {
        method: 'POST',
        headers: {
            // 'content-type': 'application/json',
            // 'content-type': 'multipart/form-data',
            // 'content-type': 'application/x-www-form-urlencoded',
        },
        body: body
    })
        .then((response) => {
            return response.json();
        })
};

export const OtpApiR = (body) => {
    return fetch(URL + 'api/v1/customers/signinverification', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            return response.json();
        })
};
export const OtpApi = (body) => {
    return fetch(URL + 'api/v1/accounts/signinverification', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            return response.json();
        })
};
export const GETOrders = (e) => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID') + '/orders' + e, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
    })
        .then((response) => {
            return response.json();
        })
};
export const GETRepresentatives = (e) => {
    return fetch(URL + 'api/v1/representatives/' + localStorage.getItem('ID') + '/orders?Filters=' + e, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
    })
        .then((response) => {
            return response.json();
        })
};

export const PostSerial = (e, body) => {
    return fetch(URL + 'api/v1/orders/' + e + '/deliver', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            return response.json();
        })
};
export const PostSerialCustomer = (e, body) => {
    return fetch(URL + 'api/v1/orders/' + e + '/delivered', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            return response.json();
        })
};

export const GETLicence = () => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID') + '/licences', {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
    })
        .then((response) => {
            return response.json();
        })
};

export const AddOrders = (body, id) => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID') + '/orders/draft/items/' + id + '/licence', {
        method: 'POST',
        headers: {
            // 'content-type': 'application/json',
            // 'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
        body: body
    })
        .then((response) => {
            return response.json();
        })
};

export const SubmitOrders = (id) => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID') + '/orderitems/' + id + '/submit', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            // 'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};
export const DeleteOrders = (id) => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID') + '/orders/draft/items/' + id, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};

export const GetProduct = (id) => {
    return fetch(URL + 'api/v1/products', {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};
export const GetSerials = (e) => {
    return fetch(URL + 'api/v1/serials' + e, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};

export const AddItemOrders = (body) => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID') + '/orders/draft/items', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            return response.json();
        })
};
export const SubmitItemOrders = (id) => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID') + '/orders/' + id + '/submit', {
        method: 'POST',
        headers: {
            // 'content-type': 'application/json',
            // 'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
        // body: body
    })
        .then((response) => {
            return response.json();
        })
};

export const GetSubmitItemOrders = () => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID') + '/orders/submittable', {
        method: 'GET',
        headers: {
            // 'content-type': 'application/json',
            // 'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};

export const GetAdminOrders = (e) => {
    return fetch(URL + 'api/v1/orders?Filters=' + e, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};
export const GetAddress = () => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID') + '/addresses', {
        method: 'GET',
        headers: {
            // 'content-type': 'application/json',
            // 'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};
export const AddAddress = (body) => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID') + '/addresses', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            // 'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            return response.json();
        })
};
export const GetState = () => {
    return fetch(URL + 'api/v1/provinces', {
        method: 'GET',
        headers: {
            // 'content-type': 'application/json',
            // 'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};
export const GetCity = (id) => {
    return fetch(URL + 'api/v1/provinces/' + id + '/cities', {
        method: 'GET',
        headers: {
            // 'content-type': 'application/json',
            // 'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};

export const Pay = (body, orderId) => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID') + '/orders/' + orderId + '/submit', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            // 'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            return response.json();
        })
};
export const Type = () => {
    return fetch(URL + 'api/v1/orders/deliverytypes', {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
    })
        .then((response) => {
            return response.json();
        })
};
export const DeleteAddress = (id) => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID') + '/addresses/' + id, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};
export const UpdateIban = (body) => {
    return fetch(URL + 'api/v1/customers/' + localStorage.getItem('ID'), {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            return response.json();
        })
};
export const DeleteItemAdmin = (id) => {
    return fetch(URL + 'api/v1/orders/' + id + '/cancel', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};
export const InrepresentativeAdmin = (id, re) => {
    return fetch(URL + 'api/v1/orders/' + id + '/inrepresentativestock', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
        body: JSON.stringify(re)
    })
        .then((response) => {
            return response.json();
        })
};
export const PostSerialAdmin = (body) => {
    return fetch(URL + 'api/v1/serials/', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            return response.json();
        })
};
export const PayAgain = (body) => {
    return fetch(URL + 'api/v1/orders/' + body + '/pay', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};
export const GetUser = (e) => {
    return fetch(URL + 'api/v1/customers/?' + e, {
        method: 'GET',
        headers: {
            // 'content-type': 'application/json',
            // 'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.json();
        })
};

export const GetEx = () => {
    return fetch(URL + 'api/v1/reports/orders', {
        method: 'GET',
        headers: {
            // 'content-type': 'application/json',
            // 'Access-Control-Allow-Origin': 'no-cors',
            'Authorization': 'Bearer ' + localStorage.getItem('TOKEN'),
        }
    })
        .then((response) => {
            return response.blob();
        })
};

import React, {useEffect, useState} from 'react';
import Edit from "../assets/img/1x/edit.png";
import {CheckToken, GETOrders, PayAgain, Separator, UpdateIban} from "../api";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import Spinner from "../assets/img/SVG/Spinner.svg";
import {useHistory} from "react-router-dom";

const Profile = () => {
    const data = useSelector(state => state);
    const dispatch = useDispatch()
    const [information, setInformation] = useState([])
    // const [iban, setIban] = useState('')
    const history = useHistory()
    const notify = (e) => toast.success(e);
    const [disable, setDisable] = useState({sheba: true})
    const [load, setLoad] = useState(false)
    const [input, setInput] = useState({
        sheba: !!data.login.iban ? data.login.iban : '',
        name: !!data.login.firstName ? data.login.firstName : '',
        family: !!data.login.lastName ? data.login.lastName : '',
        meli: !!data.login.nationalId && data.login.nationalIdConfirmed ? data.login.nationalId : ''
    })
    const error = (e) => toast.error(e);
    useEffect(() => {
        let url = new URLSearchParams(history.location.search);
        let search = url.get('isPaid')
        if (!!search && search.toLocaleLowerCase() == 'true') {
            notify('با موفقیت پرداخت شد.')
        } else if (!!search && search.toLocaleLowerCase() == 'false') {
            error('عملیات پرداخت ناموفق بود.')
        }
        if (!!search) {
            setTimeout(() => {
                history.push('/dashboard/profile')
            }, 2000)
        }
        CheckToken().then((response) => {
            if (response.status === 200) {
                dispatch({type: 'login', startApp: true, login: response.data})
            }
            // setIban(!!response.data.iban ? response.data.iban.replace('IR', '') : '')
            setInput({...input, sheba: !!response.data.iban ? response.data.iban.replace('IR', '') : ''})
        })
        setLoad(true)
        GETOrders('?filters=status!=drafted').then((response) => {
            if (response.status === 200) {
                setLoad(false)
                setInformation(response.data)
            } else {
                setLoad(false)
            }

        })
    }, [])
    const pay = (id) => {
        PayAgain(id).then((response) => {
            if (response.status === 200) {
                if (response.data.transporter === 1) {
                    window.open(response.data.transporter.url, "_self")
                } else {
                    const form = document.createElement('form');
                    form.setAttribute('id', 'myForm');
                    form.setAttribute('method', 'post');
                    form.setAttribute('action', response.data.transporter.url);
                    response.data.transporter.form.forEach(item => {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'hidden');
                        input.setAttribute('name', item.key);
                        input.setAttribute('value', item.value);
                        form.appendChild(input);
                    });
                    document.getElementsByTagName('body')[0].appendChild(form);
                    document.getElementById("myForm").submit();
                }
            } else {
                error('مشکل در ارتباط با سرور')
            }
        })
    }
    const editable = () => {
        setDisable({...disable.sheba, sheba: false})
    }

    const status = (v) => {
        switch (v) {
            case 'drafted':
                return 'سبد خرید'
                break;
            case 'submitted':
                return 'در انتظار پرداخت'
                break;
            case 'successfulpayment':
                return 'پرداخت موفق و در حال ارسال'
                break;
            case 'unsuccessfulpayment':
                return 'پرداخت ناموفق'
                break;
            case 'inrepresentativestock':
                return 'موجود در انبار نماینده'
                break;
            case 'delivering':
                return 'تحویل مأمور ارسال'
                break;
            case 'cancelled':
                return 'لغو شده'
                break;
            case 'delivered':
                return 'تحویل شده'
                break;
        }

    }

    const saveDataPrivacy = () => {
        if (((!!input.sheba && input.sheba.length >= 24) || input.name.length > 0 || input.family.length > 0) && input.meli.length >= 9) {
            let body = {
                iban: input.sheba.length > 0 ? 'IR' + input.sheba : '',
                firstName: input.name,
                lastName: input.family,
                nationalId: input.meli,
            }
            UpdateIban(body).then((response) => {
                if (response.status === 200) {
                    setDisable({...disable.sheba, sheba: true})
                    CheckToken().then((response) => {
                        if (response.status === 200) {
                            dispatch({type: 'login', startApp: true, login: response.data})
                            setInput({
                                ...input,
                                sheba: !!response.data.iban ? response.data.iban.replace('IR', '') : ''
                            })
                        }
                        // setIban(!!response.data.iban ? response.data.iban.replace('IR', '') : '')

                    })
                    notify('با موفقیت ثبت شد')
                } else {
                    error(!!response.validationResult.errors[0] ? response.validationResult.errors[0].errorMessage : 'اطلاعات خود را به درستی وارد کنید')


                }
            })
        } else {
            error('اطلاعات خود خود را به درستی وارد کنید')
        }
    }
    return (
        <div className='profile-home'>
            <div className="title">
                <h6 className={'d-flex justify-content-between align-items-center'}> اطلاعات شخصی <span
                    className={'cursor-p'} onClick={() => {
                    localStorage.clear()
                    window.location.reload()
                }}>خروج از حساب کاربری</span></h6>
            </div>
            <div className="line-space"/>
            <div className="information-private flex-column text-center">
                <div className="d-flex justify-content-between text-right flex-wrap">
                    <div className="information-private-row">
                        <div className="information-private-column">
                            <span>نام :</span>
                            {/*<span>  {(!!data.login && !!data.login.firstName) ? data.login.firstName + '   ' + data.login.lastName : ''}</span>*/}
                            <input type="text" placeholder={'نام'}
                                   className={'pl-3 pr-3'}
                                   value={input.name}
                                   style={{textAlign: 'right'}}
                                   onChange={(e) => setInput({
                                       ...input,
                                       name: e.target.value
                                   })}
                                   disabled={disable.sheba}/>
                        </div>

                        <div className="information-private-column">
                            <span>نام خانوادگی :</span>
                            {/*<span>  {(!!data.login && !!data.login.firstName) ? data.login.firstName + '   ' + data.login.lastName : ''}</span>*/}
                            <input type="text" placeholder={'نام خانوادگی'}
                                   className={'pl-3 pr-3'}
                                   value={input.family}
                                   style={{textAlign: 'right'}}
                                   onChange={(e) => setInput({
                                       ...input,
                                       family: e.target.value
                                   })}
                                   disabled={disable.sheba}/>
                        </div>

                        <div className="information-private-column">
                            <span>شماره تلفن همراه :</span>
                            <span> {(!!data.login && !!data.login.phoneNumber) ? data.login.phoneNumber : ''}</span>
                        </div>
                    </div>
                    <div className="information-private-row">
                        <div className="information-private-column">
                            <span>کدملی :</span>
                            {/*<span> {(!!data.login && !!data.login.nationalId) ? data.login.nationalId : ''}</span>*/}
                            <input type="tel" placeholder={'کد ملی'}
                                   className={'pl-3 pr-3'}
                                   value={input.meli}
                                   style={{textAlign: 'left', direction: "ltr"}}
                                   onChange={(e) => setInput({
                                       ...input,
                                       meli: e.target.value
                                   })}
                                   disabled={disable.sheba}/>
                        </div>
                        <div className="information-private-column">
                            <span>روش بازگشت وجه :</span>
                            <input type="text" placeholder={'*************5456981'}
                                   className={'pl-3 pr-3'}
                                   value={input.sheba}
                                   style={{textAlign: 'left', direction: "ltr"}}
                                   onChange={(e) => setInput({
                                       ...input,
                                       sheba: e.target.value
                                   })}
                                   disabled={disable.sheba}/>
                            <span className={'ir'}>IR</span>
                        </div>
                    </div>
                </div>


                {!disable.sheba ?
                    <div className="align-self-center w-md-25 cursor-p" onClick={() => saveDataPrivacy()}><span>ذخیره اطلاعات</span>
                    </div>
                    :
                    <span>
                      <span>
                        <img width={18} src={Edit} alt="" className={'pl-1'}/>
                    </span>
                    <span className={'edith'} onClick={() => editable()}>ویرایش اطلاعات شخصی</span>

                </span>
                }
            </div>
            <div className="last-orders">
                <div className="title">
                    <h6>سوابق خرید</h6>
                </div>
                <div className='allOrders'>
                    <div className="shoppingList-box">
                        {load &&
                        <div className={'m-auto'}>
                            <img width={55} src={Spinner} alt=""/>
                        </div>
                        }
                        {!!information && information.length > 0 &&
                        information.map((val, i) => {
                            return <div key={i} className="history-column">
                                {!!val.orderItems &&
                                val.orderItems.map((v, i) => {
                                    return <div key={i} className="d-flex justify-content-between w-100">
                                        <div
                                            className="d-flex align-items-center justify-content-center flex-wrap w-100">
                                            <div className="d-flex flex-wrap justify-content-between w-100 pt-2"
                                                 style={{marginRight: 31}}>
                                                {
                                                    !!v.licence ?
                                                        <>
                                                            <span>تابلو فرمان Light با درایو L100 7.5 KW</span>
                                                            <span className={'pl-3'}>
                                                        ﺷﻤﺎره ﭘﺮواﻧﻪ : {Separator(v.licence.number)}
                                                        </span>
                                                        </>
                                                        :
                                                        <span>UPS 1.2 KVA</span>
                                                }


                                                <span className={'pl-4'}>
                                                        {Separator(v.totalPrice)} تومان
                                                </span>
                                                {/* <span style={{textAlign: "left"}}>
                                                    <span className={'w-s-4'}>وضعیت سفارش  :  </span>
                                                    <span className='fs-9 mb-0 w-s-4'> {!!val.address ? val.address.line : val.deliveryType} </span>
                                                </span>*/}
                                            </div>
                                        </div>

                                    </div>
                                })
                                }
                                <div className="profile-history">
                                    <div className="d-flex pr-4 p-2 justify-content-between flex-wrap">
                                        <p className={'mb-0 p-2 profile-history-item'}>
                                            <span
                                                className={'w-s-4'}>{val.deliveryType === 'fca' ? 'آدرس    : ' : ''}</span>
                                            <span
                                                className='fs-9 mb-0 w-s-4'>  {!!val.address ? val.address.line : val.deliveryType === 'cip' ? 'تحویل از نمایندگی' : 'تحویل از کارخانه'} </span>
                                        </p>
                                        <p className={'pl-3 mb-0 p-2'}>
                                            <span className={'w-s-4'}>وضعیت سفارش  :  </span>
                                            <span
                                                className='fs-9 mb-0 w-s-4'> {!!val.status ? status(val.status) : ''} </span>
                                        </p>

                                    </div>
                                    <div
                                        className="d-flex flex-row pr-4 p-2 justify-content-between flex-wrap profile-history-item">


                                        {!!val.payment && val.payment.isPaid && !!val.payment.trackingNumber && val.status !== 'unsuccessfulpayment' &&
                                        <span className={'ml-3'}>
                                                                شماره تراکنش : {Separator(val.payment.trackingNumber)}
                                                            </span>
                                        }
                                        {val.formalInvoiceCost > 0 &&
                                        <p className={'pl-3 mb-0 p-2'}>
                                            <span className={'pl-1 '}>هزینه ارسال  :  </span>
                                            <span
                                                className=''> {!!val.deliveryCost ? Separator(val.deliveryCost) : '0'} تومان </span>
                                        </p>

                                        }
                                        <p className={'pl-3 mb-0 p-2'}>
                                            <span className={'w-s-4'}>قیمت کل :  </span>
                                            <span
                                                className='fs-9 mb-0 w-s-4'> {!!val.totalPrice ? Separator(val.totalPrice) : ''} تومان </span>
                                        </p>
                                        {(val.status === 'unsuccessfulpayment' || val.status === 'submitted') &&
                                        <div className="bottom w-25"
                                             onClick={() => pay(val.id)}>
                                            <span>پرداخت</span></div>
                                        }
                                        {/*<p className={'pl-3 mb-0 p-2'}>*/}
                                        {/*    <span className={'w-s-4'}>هزینه ارسال :  </span>*/}
                                        {/*    <span*/}
                                        {/*        className='fs-9 mb-0 w-s-4'> {!!val.deliveryCost ? Separator(val.deliveryCost)  : ''} تومان </span>*/}
                                        {/*</p>*/}
                                    </div>
                                </div>
                            </div>
                        })
                        }
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Profile;

import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Delete from "../assets/img/1x/delete.png";
import Drive from "../assets/img/1x/Drive-Small.png";
import Ups from "../assets/img/1x/UPS.png";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
    AddAddress, DeleteAddress,
    GetAddress,
    GetCity,
    GetState,
    GetSubmitItemOrders, Pay, RepresentativeId,
    Separator
} from "../api";
import Spinner from "../assets/img/SVG/Spinner.svg";
import * as Sentry from '@sentry/react';

// 09150490029
let all = {state: [''], city: ['']}
const ShoppingList = () => {
    const [load, setLoad] = useState(false);
    const [information, setInformation] = useState(undefined)
    const [address, setAddress] = useState([])
    const [showAdd, setShowAdd] = useState(false)
    const [text, setText] = useState({address: '', tel: '', code: ''})
    const [country, setCountry] = useState({state: [''], city: ['']})
    const [countryEnter, setCountryEnter] = useState({state: '', city: '', stateId: '', cityId: ''})
    const [checkBox, setCheckBox] = useState({
        factory: {show: false, id: ''},
        agent: {show: false, id: ''},
        address: {show: false, id: ''},
        addressItem: {show: {}, id: 0},
        deliveryTypeId: -1,
        addressId: '',
        factor: false
    })
    const analytics = getAnalytics();
    const [countryShow, setCountryShow] = useState({state: false, city: false})
    const notify = (e) => toast.success(e);
    const errors = (e) => toast.error(e);
    const warning = (e) => toast.warning(e);
    const copy =(e)=>{
        try {
            navigator.clipboard.writeText('09394237860')
            notify('با موفقیت کپی شد')
        }catch (e) {

        }
    }
    console.log(checkBox)
    const totalPrice = () => {
        return (!!information ? information.totalPrice : 0) + ((!!checkBox.addressItem.id && checkBox.deliveryTypeId == 3) ? checkBox.addressItem.id.isCentral ? 100000 : 250000 : 0)
    }
    function FallbackComponent() {
        return (
            <div>مشکل در پرداخت</div>
        )
    }

    const addAddress = () => {
        if (text.tel.length === 11 && text.code.length === 10) {
            setLoad(true)
            let body = {
                provinceId: countryEnter.stateId,
                cityId: countryEnter.cityId,
                line: text.address,
                zipCode: text.code,
                phoneNumber: text.tel
            }
            AddAddress(body).then((response) => {
                if (response.status === 200) {
                    setLoad(false);
                    GetAddress().then((response) => {
                        if (response.status === 200) {
                            setAddress(response.data)
                            // setCheckBox({...checkBox, addressItem: {show: {}, id: 0}})
                            if (response?.data && response.data?.length > 0){
                                setCheckBox({
                                    ...checkBox,
                                    addressItem: {
                                        show: {[0]: true},
                                        id: response.data[0].city
                                    },
                                    addressId: response.data[0].id,
                                })
                            }
                        }
                    })
                    setShowAdd(false)
                }
            })

        } else if (text.tel.length !== 11) {
            errors('شماره همراه را به صورت کامل وارد نمایید')
        } else if (text.code.length !== 10) {
            errors('کد پستی 10 رقم میباشد')
        }
    }
    // useEffect(() => {
    //     Type().then((response) => {
    //         console.log(response)
    //     })
    // }, [])

    const sendToServer = (e) => {
        logEvent(analytics, 'goToPay-chineh');
        if ((checkBox.factory.show && checkBox.addressId.length > 0) || (checkBox.agent.show && checkBox.addressId.length > 0) || (checkBox.address.show && checkBox.addressId.length > 0)) {
            let body = {
                deliveryTypeId: checkBox.deliveryTypeId,
                addressId: checkBox.addressId,
                // representativeId: "5ddc75ec-6b5b-4892-baf2-27713a41296c",
                representativeId: RepresentativeId,
                requireFormalInvoice: checkBox.factor
            }
            Pay(body, e).then((response) => {
                if (response.status === 200 && response.validationResult.errors.length === 0) {
                    if (response.data.transporter === 1) {
                        window.open(response.data.transporter.url, "_self")
                    } else {
                        const form = document.createElement('form');
                        form.setAttribute('id', 'myForm');
                        form.setAttribute('method', 'post');
                        form.setAttribute('action', response.data.transporter.url);
                        response.data.transporter.form.forEach(item => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'hidden');
                            input.setAttribute('name', item.key);
                            input.setAttribute('value', item.value);
                            form.appendChild(input);
                        });
                        document.getElementsByTagName('body')[0].appendChild(form);
                        document.getElementById("myForm").submit();
                    }
                }else if(response.validationResult.errors.length > 0){
                    errors(response.validationResult?.errors[0]?.errorMessage);
                } else {
                    errors('لطفا آدرس را وارد نمایید ')
                }
            })
        } else {
            errors('لطفا روش تحویل  و یا آدرس را انتخاب کنید! ')
        }
    }

    useEffect(() => {

        GetSubmitItemOrders().then((response) => {
            if (response.status === 200) {
                setInformation(!!response.data ? response.data : [])
                if (!!response.data && !!response.data.orderItems) {
                    let removed = response.data.orderItems.filter((v)=> v.isRemoved === true)
                    if (!!removed) {
                        removed.map((v, i)=> warning(v.product.title  + ' به علت اتمام موجودی از سبد خرید حذف شد. '))
                    }
                }
            }
        })

        GetAddress().then((response) => {
            if (response.status === 200) {
                setAddress(response.data)
                if (response?.data && response.data?.length > 0){
                    setCheckBox({
                        ...checkBox,
                        addressItem: {
                            show: {[0]: true},
                            id: response.data[0].city
                        },
                        addressId: response.data[0].id,
                    })
                }

            }
        })

    }, [])

    const selectState = (id, name) => {
        GetCity(id).then((response) => {
            setCountry({...country, city: response.data})
            setCountryShow({...countryShow, state: false})
            all.city = response.data
            setCountryEnter({
                ...countryEnter,
                state: name,
                stateId: id
            })
        })
    }

    const selectStateInput = () => {
        GetState().then((response) => {
            if (response.status === 200) {
                setCountry({...country, state: response.data})
                all.state = response.data
            }
        })
        setCountryShow({...countryShow, state: !countryShow.state})
    }

    const search = (e, v, type) => {
        setCountryEnter(type === 'state' ? {...countryEnter, state: e.target.value} : {
            ...countryEnter,
            city: e.target.value
        })
        let result = v.filter(h => !!h.name && h.name.includes(e.target.value))

        if (result && e.target.value.length > 1 && type === 'state') {
            setCountry({...country, state: result})
        } else {
            setCountry({...all})
        }

        if (result && e.target.value.length > 1 && type === 'city') {
            setCountry({...country, city: result})
        } else {
            setCountry({...all})
        }
    }

    const DeleteItem = (i, id) => {
        DeleteAddress(id).then((response) => {
            if (response.status === 200) {
                notify('با موفقیت حذف شد')
                let array = [...address];
                array.splice(i, 1)
                setAddress(array)
            }
        })

    }


    return (
        <>
            {/*{alert && <Alert text={''}/>}*/}
            <div className='shoppingList'>
                <div className="line-space mb-2"/>
                <h6 className={'d-flex align-self-baseline'}>
                    سبد خرید
                </h6>
                <div className="line-space mb-2"/>
                <>
                    {
                        !!information && !!information.orderItems && !!information.orderItems[0] ?
                            <>

                                <div className="shoppingList-box">
                                    {
                                        !!information.orderItems &&
                                        information.orderItems.filter((v)=> v.isRemoved === false).map((v, i) => {
                                            return <div key={i}
                                                        className={"shoppingList-box-row " + (v.isRemoved ? '' : '')}>
                                                <div
                                                    className="d-flex align-items-center flex-wrap w-100 justify-content-between">

                                                    {/*<div className="d-flex justify-content-between flex-wrap w-25" style={{marginRight: 25}}>*/}
                                                    {
                                                        v.product.type === 'ControlPanel' ?
                                                            <span className={'d-flex flex-wrap align-items-center'}>
                                                                       <img width={50} height={55} src={Drive} alt=""/>
                                                                          <span className={'mr-3'}>تابلو فرمان Light با درایو L100 7.5 KW</span>
                                                                       <span className={'mr-3'}>
                                                                            ﺷﻤﺎره ﭘﺮواﻧﻪ : {!!v.licence ? v.licence.number : ''}
                                                                       </span>

                                                 </span>
                                                            :
                                                            <span
                                                                className="input-icon d-flex flex-wrap align-items-center">
                                                <img width={50} height={55} src={Ups} alt=""/>
                                                                {/*<span className={'ml-3 mr-3'}>UPS</span>*/}
                                                                <span className={'mr-3'}>UPS 1.2 KVA</span>
                                                <span
                                                    className={'ml-3 mr-3'}> تعداد    : <span>{v.units}</span>  </span>
                                                </span>
                                                    }
                                                    <span className={'d-flex flex-column '}>
                                                        {
                                                            v.discount > 0 &&
                                                            <span>

                                                                <span
                                                                    className={'price-line'}>{Separator(v.unitPrice * v.units)}</span>
                                                                    <span className={'space'}> تومان</span>
                                                            </span>
                                                        }

                                                            <span>
                                                                  {Separator(v.totalPrice)}
                                                                <span className="space"/>
                                                                    <span>تومان </span>
                                                            </span>
                                                         </span>
                                                    {/*</div>*/}

                                                </div>
                                                {/*<img width={20} src={Delete} alt="" onClick={() => DeleteItem(i, v.id)}/>*/}
                                            </div>
                                        })
                                    }
                                </div>

                                <div className="line-space"/>
                                <div className="shoppingList-paying">
                                    <div className="delivery">
                                        <label>

                                            <input type="radio" id="html" name="a" value={checkBox.factory.show}
                                                   onChange={(e) => setCheckBox({
                                                       ...checkBox,
                                                       factory: {show: !!e.target.value, id: '2'},
                                                       address: {show: !!e.target.value, id: ''},
                                                       agent: {show: false, id: ''},
                                                       deliveryTypeId: 2,
                                                   })}/>
                                            <span></span>
                                        </label>
                                        <div className={'d-flex flex-wrap pr-4 w-100'}>
                                        <span className={'mr-0'}>
                  تحویل درب کارخانه
                    </span>

                                            <span>
                       0 تومان
                    </span>
                                            <span>
                                            ﺷﻬﺮک ﺻﻨﻌﺘﯽ ﺷﻤﺲ آﺑﺎد ،ﺑﻠﻮار ﺑﻬﺎرﺳﺘﺎن ،خیاﺑﺎن ۱۹ ﯾﺎ ﭘﻼک ۱۳۷ بوﻋﻠﯽ ﺳﯿﻨﺎ ،ﮐﻮﭼﻪ ﻧﺮﮔﺲ ﺳﻮم ،ﻗﻄﻌﻪ ﺗﯽ اف
                                        </span>

                                        </div>
                                    </div>
                                    <div className="delivery">
                                        <label>
                                            <input type="radio" id="html" name="a" value={checkBox.agent.show}
                                                   onChange={(e) => setCheckBox({
                                                       ...checkBox,
                                                       agent: {show: !!e.target.value, id: '4'},
                                                       address: {show: !!e.target.value, id: ''},
                                                       factory: {show: false, id: ''},
                                                       deliveryTypeId: 4,
                                                   })}/>
                                            <span></span>
                                        </label>
                                        <div className={'d-flex flex-wrap pr-4 w-100'}>
                                        <span className={'mr-0'}>
                ﺗﺤﻮﯾﻞ از ﻧﻤﺎﯾﻨﺪه اﺳﺘﺎن
                    </span>

                                            <span>
                                             0 تومان
                                        </span>

                                            <span>
                                            مشهد، بلوار وکیل آباد 43، صدف 15، پلاک 41/1
                                        </span>
                                        </div>
                                    </div>
                                    <div className="delivery">

                                        <label>
                                            <input type="radio" id="html" name="a" value={checkBox.address.show}
                                                   onChange={(e) => setCheckBox({
                                                       ...checkBox,
                                                       address: {show: !!e.target.value, id: '3'},
                                                       agent: {show: false, id: ''},
                                                       factory: {show: false, id: ''},
                                                       deliveryTypeId: 3,
                                                   })}/>
                                            <span></span>
                                        </label>

                                        <span>
                ارﺳﺎل ﺑﻪ آدرس
                    </span>

                                        <p className={'mb-0 '}>
                                            باتوجه به آدرس انتخابی
                                        </p>

                                    </div>

                                    <div className="line-space"/>
                                    {
                                        address.length > 0 && checkBox.address.show &&
                                        address.map((v, i) => {
                                            return <div key={i} className="address">
                                                <p>
                                                    <label>
                                                        <input type="radio" id="html" name="address"
                                                               checked={!!checkBox.addressItem.show[i] ? checkBox.addressItem.show[i] : false}
                                                               value={!!checkBox.addressItem.show[i] ? checkBox.addressItem.show[i] : false}
                                                               onChange={(e) => setCheckBox({
                                                                   ...checkBox,
                                                                   addressItem: {
                                                                       show: {[i]: !!e.target.value},
                                                                       id: v.city
                                                                   },
                                                                   addressId: v.id,
                                                               })}/>
                                                        <span> </span>
                                                    </label>
                                                    <span>آدرس : </span>
                                                    <span>{v.province.name}, {v.city.name}, {v.line} </span>

                                                </p>
                                                <p className={'lh-3 pl-1'}><span>کدپستی : </span>
                                                    <span>  {v.zipCode} </span></p>
                                                <p className={'lh-3 pl-1'}><span>شماره همراه : </span>
                                                    <span>  {v.phoneNumber} </span></p>
                                                <img className={'cursor-p'} src={Delete} width={14} height={14} alt=""
                                                     onClick={() => DeleteItem(i, v.id)}/>
                                            </div>
                                        })
                                    }
                                    {
                                        checkBox.address.show &&
                                        <div className="add-address" onClick={() => setShowAdd(!showAdd)}>
                                            <span>افزودن آدرس</span>
                                        </div>
                                    }


                                    {
                                        showAdd && checkBox.address.show &&
                                        <div className="add-address-page">

                                            <div className="input-country">
                                                <div className="state">
                                                    <input type="text" placeholder={'نام استان را انتخاب کنید'}
                                                           className='state-input'
                                                           value={countryEnter.state} onClick={() => selectStateInput()}
                                                           onChange={(e) => search(e, country.state, 'state')}/>
                                                    {
                                                        countryShow.state &&
                                                        <div className='sub-input'>
                                                            {
                                                                country.state.map((v, index) => {
                                                                    return <span key={index}
                                                                                 onClick={() => selectState(v.id, v.name)}
                                                                    >{v.name}</span>
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div className="city">
                                                    <input type="text" placeholder={'نام شهر را انتخاب کنید'}
                                                           className='state-input'
                                                           onClick={() => setCountryShow({
                                                               ...countryShow,
                                                               city: !countryShow.city
                                                           })}
                                                           value={countryEnter.city}
                                                           onChange={(e) => search(e, country.city, 'city')}
                                                    />
                                                    {
                                                        countryShow.city &&
                                                        <div className='sub-input'>
                                                            {
                                                                country.city.map((v, index) => {
                                                                    return <span key={index}
                                                                                 onClick={() => {
                                                                                     setCountryEnter({
                                                                                         ...countryEnter,
                                                                                         city: v.name,
                                                                                         cityId: v.id
                                                                                     })
                                                                                     setCountryShow({
                                                                                         ...countryShow,
                                                                                         city: false
                                                                                     })
                                                                                 }}
                                                                    >{v.name}</span>
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="line-space"/>
                                            <div className="line-space"/>

                                            <textarea name="" id="" cols="1" rows="1" maxLength="50"
                                                      placeholder={'آدرس'}
                                                      value={text.address}
                                                      onChange={(e) => setText({
                                                          ...text,
                                                          address: e.target.value
                                                      })}> </textarea>
                                            <div className="line-space"/>
                                            <div className="information-paying">
                                                <input type="tel" placeholder={'تلفن همراه'} value={text.tel}
                                                       onChange={(e) => setText({...text, tel: e.target.value})}/>
                                                <input type="tel" placeholder={'کد پستی'} value={text.code}
                                                       onChange={(e) => setText({...text, code: e.target.value})}/>
                                            </div>
                                            <div className="line-space"/>
                                            <div className="line-space"/>
                                            <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
                                                <div className={"bottom " + (load ? 'button-noChange ' : '')}
                                                     onClick={() => !load && addAddress()}><span>افزودن</span>
                                                </div>
                                            </Sentry.ErrorBoundary>

                                        </div>
                                    }


                                </div>


                                <div className="line-space"/>
                                <div className="result">
                                    <div className="result-row">
                                        <div className="d-flex" style={{width: '60%'}}>
                                            <label>
                                                <input type="checkbox" id="html" name="a" value={checkBox.factor}
                                                       onChange={(e) => setCheckBox({
                                                           ...checkBox,
                                                           factor: !checkBox.factor,
                                                       })}/>
                                                <span></span>
                                            </label>
                                            <div className="factor">
                                            <span className=''>
                          ارسال فاکتور رسمی
                    </span>

                                                <span>
                            {Separator(0)} تومان
                    </span>
                                            </div>
                                        </div>
                                        <div className="total">
                           <span className={'pl-2'}>

                               جمع  :
                    </span>
                                            <span>
                            {Separator(totalPrice())} تومان
                        </span>
                                        </div>

                                    </div>
                                    <div className="line-space"/>
                                    <div className="d-flex  flex-column align-items-end">
                                        <span style={{fontSize: 13}}>در این مبلغ  9 درصد مالیات بر ارزش افزوده لحاظ شده است</span>
                                        <div className="bottom w-25"
                                             onClick={() => sendToServer(information.id)}>
                                            <span>پرداخت از طریق درگاه بانکی</span></div>
                                    </div>
                                    <div className="d-flex  flex-column align-items-end">
                                        <span className={'orange-color '} style={{fontSize: 12}}>
                                            در صورت داشتن سوال به واتساپ <span className={'cursor-p'} onClick={copy}>09394237860</span> پیام دهید.
                                        </span>
                                        {/*<span style={{fontSize: 13}}>پس از پرداخت از طریق شماره شبا لطفا رسید بانکی خود را به واتس آپ <a href='https://api.whatsapp.com/send?phone=9893942378608&text=' target={'_blank'} className={'orange-color'}>  09394237860  </a> ارسال نمایید.</span>*/}
                                        {/*<div className="bottom w-25 d-flex flex-column"*/}
                                        {/*     onClick={() => sendToServer(information.id)}>*/}
                                        {/*    <span>پرداخت از طریق شماره شبا</span>*/}
                                        {/*    <span style={{fontSize: 13}}>IR750560080104002967384001</span>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </>
                            :
                            !information ?
                                <div className={'m-auto'}>
                                    <img width={55} src={Spinner} alt=""/>
                                </div>
                                :
                                <div className={'d-flex justify-content-center align-items-center h-100'}>
                                    <p>سبد خرید شما خالی میباشد.</p>
                                </div>
                    }
                </>
            </div>
        </>
    );
};

export default ShoppingList;

import React from 'react';
import {useHistory} from "react-router-dom";

const SuccessfullyPayment = () => {

    const history = useHistory();
    return (<div className={'SuccessfullPayment'}>
            <p style={{color: '#00590a'}}>تراکنش با موفقیت انجام شد.</p>

        <div className="line-space"/>
        <div className="line-space"/>

        <div className={"bottom "}>
            <span onClick={() => history.replace('AllOrders')}>بازگشت به صفحه سفارش ها</span>
        </div>
        </div>
    );
};

export default SuccessfullyPayment;

import React, {useEffect, useState} from 'react';
import Positive from "../assets/img/SVG/positive.svg";
import ExampleDrive from "../assets/img/1x/example-drive_1.png";
import Upload from "../assets/img/SVG/uploadLicense.svg";
import {GETOrders} from "../api";
import {useSelector} from "react-redux";

// در انتظار
const Expectation = () => {
    const data = useSelector(state => state);
    const [upload, setUpload] = useState({})
    const [information, setInformation] = useState([])
    const [loading, setLoading] = useState(false)
    const [licenceEnter, setLicenceEnter] = useState([])
    const StateButton = (v) => {
        if (!!v.licence) {
            switch (!!v.licence.status ? v.licence.status : 'editable') {
                case 'editable':
                    return 'ارسال اطلاعات';
                case 'accepted':
                    return 'تایید و افزودن به سبد خرید';
                case 'awaitingvalidation':
                    return 'در حال بررسی';
                case 'rejected':
                    return 'رد شده';
                default:
                    return '';
            }
        } else {
            return 'تایید و افزودن به سبد خرید';
        }

    }
    const SendInformationToServer = (i, ProductId, state, status, id) => {

        // console.log(i, ProductId, data.UPS, licenceEnter[i], data.Drive[i].file)

    }
    useEffect(() => {
        GETOrders('?filters=status==drafted').then((response) => {
            if (response.status === 200) {
                setInformation(response.data)
            }

        })
    }, [])

    return (
        <div className='allOrders'>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="order-add">
                <img width={35} src={Positive} alt=""/>
                <div className="order-box">
                    <p className='mb-0'>
                        ﺑﺮای اﻓﺰودن ﺳﻔﺎرش ﺟﺪﯾﺪ از روی ﻣﺤﺼﻮﻻت در اﯾﻦ ﻗﺴﻤﺖ اﻗﺪام ﮐﻨﯿﺪ
                    </p>
                    <div className="order-status">
                        <div className="bottom"><span> افزودن سفارش جدید</span></div>
                    </div>
                </div>

            </div>

            <div className="line-space"/>
            <div className="line-space"/>

            {!!information[0] &&
            information[0].orderItems.filter(v => !!v.licence && v.licence.status === 'awaitingvalidation').map((v, i) => {
                return <div key={i} className="order">
                    <div className="d-flex align-items-center flex-wrap">
                        <img width={85} height={85} src={ExampleDrive} className='ml-3' alt=""/>
                        {
                            !!v.licence ?
                                <div className="order-information">
                                    <input type="text" placeholder={v.licence.number} disabled={true}/>
                                    <div className="line-space"/>
                                    <div className="input-icon">
                                        <label htmlFor="licenses">
                                            <input type="file" id='licenses' accept="image/*"
                                                   onChange={(e) => setUpload(e.target.files[0])} hidden
                                                   disabled={true}/>
                                            <img src={Upload} width={40} alt=""/>
                                            <input type="text" value={upload.name}
                                                   placeholder={'عکس آپلود شده'} disabled={true}/>
                                        </label>

                                    </div>
                                </div>
                                :

                                <div className="input-icon">
                                    <p>UPS</p>
                                    <span>تعداد :</span>
                                    {v.units}
                                </div>
                        }

                    </div>

                    <div className="order-status">
                        <p></p>
                        <p></p>
                        <div
                            className={"bottom mb-0 " + (!!v.licence ? (!!v.licence.status && v.licence.status === 'accepted' ? 'button-orange' : v.licence.status === 'rejected' ? 'button-red' : 'button-noChange') : 'button-orange')}
                            onClick={() => {
                                if (!!v.licence) {
                                    v.licence.status = 'accepted'
                                }
                                !loading &&  SendInformationToServer(i, 'c6bff005-d1b0-453f-9fbc-27a474d293c6', v.product.type !== 'UninterruptiblePowerSupply' ? v.licence.status = !!'accepted' : true,!!v.licence ? v.licence.status : '', v.id)
                            }}>
                            <span>{StateButton(v)}</span></div>
                    </div>
                </div>
            })
            }

        </div>
    );
};

export default Expectation;

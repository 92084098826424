import React, {useEffect, useState} from 'react';
import '../assets/css/bootstrap.min.css';
import '../assets/css/style.css';
import '../assets/css/responsive.css';
import Return from '../assets/img/SVG/return.svg';
import Drives from '../assets/img/SVG/Drive-main.svg';
import UPSs from '../assets/img/SVG/UPS-main.svg';
import Arrow from '../assets/img/SVG/Arrow.svg';
import Arrow1 from '../assets/img/SVG/Arrow-1.svg';
import Spesial from '../assets/img/SVG/spesial.svg';
import present from '../assets/img/PNG/Asset 4.png';
import presentShows from '../assets/img/SVG/Asset 6.svg';
import Garanty from '../assets/img/SVG/garanty.svg';
import Fast from '../assets/img/SVG/fast.svg';
import insta from '../assets/img/SVG/insta.svg';
import telegram from '../assets/img/SVG/telagram.svg';
import stars from '../assets/img/SVG/stars.svg';
import datis from '../assets/img/SVG/datis.svg';
import Diagram from '../assets/img/SVG/Diagram.svg';
import One from '../assets/img/icons/Asset 5.svg';
import Two from '../assets/img/icons/Asset 6.svg';
import Three from '../assets/img/icons/Asset 7.svg';
import Open from '../assets/img/SVG/open.svg';
import Panel from '../assets/img/SVG/panel.svg';
import Four from '../assets/img/icons/Asset 8.svg';
import Five from '../assets/img/icons/Asset 9.svg';
import Logos from "../assets/img/1x/PNG/Asset 1.png";
import Drive from "../assets/img/1x/Drive-Small.png";
import {Lines} from "./svg";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {GetProduct, Platform, Separator} from "../api";
import Sep from "../assets/img/sep.png";

const Main = () => {
    let data = useSelector(state => state)
    const [video, setVideo] = useState(true)
    const [show, setShow] = useState([1, 0, 0])
    const [products, setProducts] = useState([])
    useEffect(() => {
        GetProduct().then((response) => {
            if (response.status === 200) {
                setProducts(response.data)
            }
        })
        setTimeout(() => {
            setVideo(false)
        }, 1000)
    }, [])
    return (
        <div className="main-site">
            {/*<div id="loader-wrapper">*/}
            {/*    <div id="loader"></div>*/}
            {/*    <div className="loader-section section-left"></div>*/}
            {/*    <div className="loader-section section-right"></div>*/}
            {/*</div>*/}

            <header className="sticky-header home-1 ">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 d-flex align-items-center col-12 justify-content-center">
                            <div className="logo">
                                {/*<Link to="/">*/}
                                <img src={Logos} alt=""/>
                                {/*</Link>*/}
                            </div>
                        </div>
                        <div
                            className="col-md-8 col-12 d-flex justify-content-center justify-content-md-end mt-4 mb-2 mt-md-0">
                            <div className="download-btn">
                                {
                                    data.startApp === true ?
                                        <div className="">
                                            {/*<p className={'text-white p-2'}>*/}
                                            {/*    به چینه خوش آمدید*/}
                                            {/*</p>*/}
                                            <Link className={'text-white p-2'}
                                                  to={!!data.login.type && data.login.type === 'Customer' ? '/dashboard/allOrders' : data.login.type === 'Admin' ? '/admin/home' : '/agent/home'}>
                                                <img width={200} height={55} src={Panel} alt=""/>
                                            </Link>
                                        </div>
                                        :
                                        <Link
                                            to={data.startApp && !!data.login.type ? data.login.type === 'Customer' ? '/dashboard/allOrders' : '/agent/home' : '/login'}>
                                            <img width={200} height={55} src={Open} alt=""/>

                                        </Link>
                                }

                                {/*</Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="hero-area home-1" data-scroll-index="0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="hero-content">
                                <h3>پلتفرم خرید آنلاین</h3>
                                <h3>تابلو فرمان داتیس</h3>
                                <div className="line-space"/>
                                <div className="line-space"/>
                                <div className="line-space"/>
                                <span style={{display: 'flex'}}>

                                {/*<a href="#" className="hero-btn text-white oranges">٪40</a>*/}
                                    {/*<a href="#" className="hero-btn-2nd off">تخفیف تنها در استان خراسان</a>*/}
                                    {/*<img width={124} height={180} src={present} alt=""/>*/}

                                    <span style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                                                <p className={'d-flex align-items-center p-0 mb-0'}
                                                                                   style={{marginRight: 16}}>  تنها در استان خراسان</p>
                                        {products.length > 0 &&
                                       <p className={'d-flex align-items-center p-0 mb-0'} style={{marginRight: 16}}>با قیمت استثنایی

                                               <>
                                               <span><span className={'price-line'} style={{margin: '0 8px'}}>  ({Separator(products[0].price)})  </span></span>
                                               <span> {products[0].isPercentDiscount ? Separator(products[0].price * (1 - (products[0].discount / 100))) : Separator(products[0].price - products[0].discount)} تومان </span>
                                               </>
                                       </p>
                                        }
                                    </span>
                                    </span>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="drive">
                    <img width={500} src={Drive} alt=""/>
                </div>
            </div>

            <div className="coll-to-action">
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <h3 className={'text-center blue-color'}><span style={{borderBottom: '1px solid #b3881e'}}>چینــه</span>
                </h3>
                <div className="features-middile-area " data-scroll-index="2">

                    <div className="container">
                        <div className="feature-middle-box" style={{position: 'relative', top: -100}}>
                            <div className="row justify-content-between">
                                <div className="col-lg-3 col-md-3">
                                    <div className="single-features-box">
                                        <img width={80} src={Spesial} alt=""/>
                                        <div className="line-space"/>
                                        <p className={'blue-color f-iransans-M'}>40 درصد
                                            تخفیف ویژه</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <div className="single-features-box">
                                        <img width={100} src={Fast} alt=""/>
                                        <div className="line-space"/>
                                        <p className={'blue-color f-iransans-M'}>ارسال سریع
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <div className="single-features-box">
                                        <img width={80} src={Return} alt=""/>
                                        <div className="line-space"></div>
                                        <p className={'blue-color f-iransans-M'}>۱۰ روز ضمانت بازگشت کالا
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <div className="single-features-box">
                                        <img width={80} src={Garanty} alt=""/>
                                        <div className="line-space"></div>
                                        <div className="line-space"></div>
                                        <p className={'blue-color f-iransans-M'}>2 سال گارانتی</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="features-middile-title">*/}
                        {/*    <div className="row">*/}
                        {/*        <div className="section-title text-center">*/}
                        {/*            <h3 className={''}>مراحل ثبت سفارش در چینــه</h3>*/}
                        {/*        </div>*/}

                        {/*    </div>*/}
                        {/*</div>*/}
                        <h4 className={'text-center blue-color mb-5'}>سامانه فروش آنلاین <span
                            className={'orange-color'}>چینــه</span></h4>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="row main-box">
                                        <div className="col-md-3">
                                            <div className="circle">
                                                <img width={140} height={185} src={Diagram} alt=""/>
                                            </div>

                                        </div>
                                        <div className="col-md-9 text-justify">
                                            <p className={'blue-color f-iransans-M'}>
                                                چینـه ﺳﺎﻣﺎنه فروش آنلاین برند داتیس در اﺳﺘﺎن‌های ﺧﺮاﺳﺎن رضوی، ﺧﺮاﺳﺎن
                                                جنوبی و
                                                ﺧﺮاﺳﺎن ﺷﻤﺎﻟﯽ اﺳﺖ.
                                            </p>

                                            <p className={'blue-color f-iransans-M'}>ما در چینــه ﺳﻌﯽ داریم ﺗﺠﺮﺑﻪ‌ی ﺧﻮبی
                                                را از
                                                ﺧﺮﯾﺪ اﯾﻨﺘﺮﻧﺘﯽ
                                                برای ﮐﺎربران و ﻣﺸﺘﺮﯾﺎن داتیس رقم بزنیم. در این ﻓﺮوﺷﮕﺎه می‌توانید محصولات
                                                داتیس
                                                را ﺑﺎ ﺑﻬﺘﺮین ﻗﯿﻤﺖ خریداری کنید، در سریع‌ترین زمان ممکن ﺗﺤﻮیل بگیرید و از
                                                خدمات
                                                پس از فروش داتیس بهره‌مند شوید؛ پس با خیال راحت خرید کنید.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/*{*/}
                {/*    data.startApp === true && data.login.type === 'Customer' &&*/}
                <div className="">
                    <div className="container">
                        <div className="row">
                            <Link
                                to={data.startApp === true && data.login.type === 'Customer' ? '/dashboard/product' : '/login'}>
                                <div className="features-img">
                                    <img width={350} src={Drives} alt=""/>
                                    <img width={350} src={UPSs} alt=""/>
                                </div>
                            </Link>

                            <div className="line-space"/>
                            <div className="line-space"/>
                            <div className="line-space"/>
                            <div className="line-space"/>
                        </div>

                    </div>
                </div>
                {/*}*/}
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="mb-5">
                    <div className="container">
                        <div className="feature-middle-box">
                            <div className="row justify-content-between">
                                <div className="col-md-2">
                                    <div className="single-features-box" style={{height: 165}}>
                                        <img width={50} src={One} alt=""/>
                                        <p className={'blue-color f-iransans-M'}>تکمیل اطلاعات شامل بارگذاری کارت
                                            ملی</p>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="single-features-box" style={{height: 165, paddingTop: 15}}>
                                        <img width={90} height={80} src={Two} alt=""/>
                                        <p className={'blue-color f-iransans-M'}>اﻧﺘﺨﺎب ﻣﺤﺼﻮل و ﻣﺸﺨﺺ ﮐﺮدن ﺗﻌﺪاد ﺳﻔﺎرش
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="single-features-box" style={{height: 165}}>
                                        <img width={70} src={Three} alt=""/>
                                        <p className={'blue-color f-iransans-M'}>ﺑﺎرﮔﺬاری پرواﻧﻪ ﺳﺎﺧﺖ ﺑﻪ ﺗﻌﺪاد ﺳﻔﺎرش ﻫﺎ
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="single-features-box" style={{height: 165}}>
                                        <img width={42} height={60} src={Four} alt=""/>
                                        <p className={'blue-color f-iransans-M'}>وارد ﮐﺮدن ﺷﻤﺎره پرواﻧﻪ ﺳﺎﺧﺖ </p>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="single-features-box" style={{height: 165}}>
                                        <img width={45} src={Five} alt=""/>
                                        <p className={'blue-color f-iransans-M'}>پرداﺧﺖ آﻧﻼین و ﺗﮑﻤﯿﻞ ﺧﺮﯾﺪ </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>


                <div className="video-overview home-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">

                                {/*{*/}
                                {/*    !video &&*/}
                                {/*    <div className="popup-video style-2 text-center">*/}
                                {/*        <a className="mfp-iframe video-play-button"*/}
                                {/*            // href="https://aparat.com/v/gxefy"*/}
                                {/*           onClick={() => setVideo(true)}*/}
                                {/*        >*/}
                                {/*            /!*<i className="fa fa-play"></i>*!/*/}
                                {/*            <img src={play} width={25} alt=""/>*/}
                                {/*        </a>*/}


                                {/*    </div>*/}
                                {/*}*/}

                                {
                                    !video &&
                                    <div className="h_iframe-aparat_embed_frame">
                                        <video controls playsInline width={250} height={150}>
                                            <source src="https://chineh.ir/chinehapi/api/v1/streams/tutorial"
                                                    type="video/mp4"/>
                                            Your browser does not support the video tag
                                        </video>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="faq-contact-area home-1" data-scroll-index="4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-center">
                                    <h3>سوالات متداول</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-65 justify-content-center">
                            <div className="col-md-8">
                                <div id="accordion" className=" style-2">
                                    <div className="card">
                                        <div className="card-header"><a
                                            className={" card-link " + (show[0] === 1 ? '' : 'collapsed')}
                                            onClick={() => setShow([show[0] === 1 ? -1 : 1, -1, -1])}>
                                            <span>چینــه ﭼﯿﺴﺖ؟</span>
                                            <Lines/>
                                            <i className="fa fa-angle-down" aria-hidden="true"
                                            > <img width={15}
                                                   height={15}
                                                   src={Arrow}
                                                   alt=""/></i>
                                            <i className="fa fa-angle-up" aria-hidden="true"
                                               style={{transform: 'rotate(180deg)', height: 25}}>
                                                <img width={15}
                                                     height={15}
                                                     src={Arrow1}
                                                     alt=""/></i>
                                        </a>
                                        </div>
                                        <div id="Xprosik" className={"collapse " + (show[0] === 1 ? 'show' : '')}
                                             data-parent="#accordion">
                                            <div className="card-body">
                                                چینــه، پلتفرم آنلاین فروش تابلوفرمان داتیس در استان خراسان است.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <a className={" card-link " + (show[1] === 2 ? '' : 'collapsed')}
                                               onClick={() => setShow([-1, show[1] === 2 ? -1 : 2, -1])}>
                                                <span> چگونه می توان از چینــه خرید کرد؟</span>
                                                <Lines/>
                                                <i className="fa fa-angle-down" aria-hidden="true"
                                                > <img width={15}
                                                       height={15}
                                                       src={Arrow}
                                                       alt=""/></i>
                                                <i className="fa fa-angle-up" aria-hidden="true"
                                                   style={{transform: 'rotate(180deg)', height: 25}}>
                                                    <img width={15}
                                                         height={15}
                                                         src={Arrow1}
                                                         alt=""/></i>
                                            </a>
                                        </div>
                                        <div id="purchase" className={"collapse " + (show[1] === 2 ? 'show' : '')}
                                             data-parent="#accordion">
                                            <div className="card-body">
                                                برای خرید کافیست در سایت ثبت نام ، به تعداد تابلوفرمان مورد نیاز، پروانه
                                                ساخت آپلود و فرآیند پرداخت آنلاین را طی کنید و در کمتر از چند ساعت سفارش
                                                خود را درب منزل تحویل بگیرید.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <a className={" card-link " + (show[2] === 3 ? '' : 'collapsed')}
                                               onClick={() => setShow([-1, -1, show[2] === 3 ? -1 : 3])}
                                               data-toggle="collapse">
                                                <span> شرایط ویژه چینــه برای مخاطبان چیست ؟</span>

                                                <Lines/>
                                                <i className="fa fa-angle-down" aria-hidden="true"
                                                > <img width={15}
                                                       height={15}
                                                       src={Arrow}
                                                       alt=""/></i>
                                                <i className="fa fa-angle-up" aria-hidden="true"
                                                   style={{transform: 'rotate(180deg)', height: 25}}>
                                                    <img width={15}
                                                         height={15}
                                                         src={Arrow1}
                                                         alt=""/></i>
                                            </a>
                                        </div>
                                        <div id="refund" className={"collapse " + (show[2] === 3 ? 'show' : '')}
                                             data-parent="#accordion">
                                            <div className="card-body">
                                                پایین ترین قیمت بازار، 10 روز ضمانت بازگشت کالا، ارسال سریع، دوسال
                                                گارانتی، تسهیل فرآیند خرید.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/*<div className="off-line">*/}
                {/*    */}
                {/*</div>*/}
                <img src={presentShows} alt="" style={{marginBottom: -31}}/>
                <footer className="home-3">
                    <div className="container">
                        <div className="line-space"/>
                        <div className="line-space"/>
                        <div className="line-space"/>
                        <div className="row ">
                            <div className="col-md-4 justify-content-center d-flex ">
                                <div className="line-space"/>
                                <div className="line-space"/>
                                <span
                                    className='text-white d-flex flex-column justify-content-between align-items-center order-last h-75'>

                                      <img width={150} src={Logos} alt="" className={'mb-4'}/>
                                         <div className="line-space"/>
                                         <div className="line-space"/>
                                           <a referrerPolicy="origin" target="_blank"
                                              href="https://trustseal.enamad.ir/?id=229605&amp;Code=OJhGMG74V5hs7i9y0Jbx">
                                        <img
                                            referrerPolicy="origin"
                                            // width={200}
                                            src="https://Trustseal.eNamad.ir/logo.aspx?id=229605&amp;Code=OJhGMG74V5hs7i9y0Jbx"
                                            alt="" style={{cursor: 'pointer'}} id="OJhGMG74V5hs7i9y0Jbx"/>
                                               {/*<img width={200} src={ENamad} alt=""/>*/}
                                        </a>
                                     <a target="_blank" href="https://sep.ir">
                                        <img
                                            width={100}
                                            src={Sep}
                                            alt="" style={{cursor: 'pointer'}}/>
                                         {/*<img width={200} src={ENamad} alt=""/>*/}
                                        </a>
                                    {/*<img width={200} src={ENamad} alt=""/>*/}
                                    </span>
                            </div>
                            <div className="col-md-4 ">
                                <div className="line-space"/>
                                <div className="line-space"/>
                                <p className='text-white'>
                                    آدرس نمایندگی: مشهد، بلوار وکیل آباد 67، دادگستر 21، پلاک 36

                                </p>
                                <p className='text-white'>
                                    ﺷﻤﺎره ﺗﻤﺎس: 05135028139
                                </p>
                                <div className="line-space"/>
                                <p className='text-white'>
                                    آدرس دﻓﺘﺮ ﻣﺮﮐﺰی: ﺗﻬﺮان، ﮐﺎرﮔﺮﺷﻤﺎﻟﯽ، ﺑﺎﻻﺗﺮ از بیمارﺳﺘﺎن ﻗﻠﺐ، ﺧیاﺑﺎن دوم،
                                    ﭘﻼک ۴۰، واﺣﺪ ۴
                                </p>
                                <p className='text-white'>
                                    آدرس ﮐﺎرﺧﺎﻧﻪ: ﺷﻬﺮک ﺻﻨﻌﺘﯽ ﺷﻤﺲ آﺑﺎد ﺑﻠﻮار ﺑﻬﺎرﺳﺘﺎن خیاﺑﺎن ۱۹ ﯾﺎ بوﻋﻠﯽ ﺳﯿﻨﺎ ﮐﻮﭼﻪ
                                    ﻧﺮﮔﺲ ﺳﻮم ﻗﻄﻌﻪ ﺗﯽ اف ﭘﻼک
                                    ۱۳۷
                                </p>
                                <p className='text-white'>
                                    ﺷﻤﺎره ﺗﻤﺎس: ۰۲۱۸۸۳۳۷۵۷۱
                                </p>
                                <p className='text-white'>
                                    آدرس اﯾﻤﯿﻞ: info@chineh.ir

                                </p>
                            </div>
                            <div className="col-md-4 d-flex flex-column align-items-center ">
                                <div className="line-space"/>
                                <div className="line-space"/>
                                <Link to={data.startApp ? '/dashboard/product' : '/login'}>
                                    <div className="bottom  text-white b-c-c w-50">
                                        <span>محصولات</span>
                                    </div>
                                </Link>
                                <br/>
                                <a href={'tel:02188337571'} className="">
                                    <div className="bottom  text-white b-c-c w-50">
                                       <span> تماس با
                                    ما</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        {/*<hr/>*/}

                    </div>

                </footer>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className=" d-flex flex-row justify-content-between align-content-center text-white"
                     style={{height: 50}}>
                    <div className="col-md-2 border align-self-center" style={{height: 1}}></div>
                    <div className='col-md-2  d-flex justify-content-center align-items-center'>
                        <a href="https://instagram.com/datis_elevator?utm_medium=copy_link" target={'_blank'}> <img
                            width={30} src={insta} alt=""/></a></div>

                    <div className='col-md-2 d-flex justify-content-center align-items-center'>
                        <a href="https://t.me/datis_elevator" target={'_blank'}>
                            <img width={30} src={telegram} alt=""/>
                        </a>
                    </div>
                    <div className='col-md-2 d-flex justify-content-center align-items-center'>
                        <a href="https://datis-elevator.ir" target={'_blank'}>
                            <img width={30} src={datis} alt=""/>
                        </a>
                    </div>
                    <div className='col-md-2 d-flex justify-content-center align-items-center'>
                        <a href={Platform == 'Windows' || Platform == 'Mac' ? "https://datis-elevator.ir/Stars" : "https://setaregan.datis-elevator.ir/pwa/"}
                           target={'_blank'}>
                            <img width={30} src={stars} alt=""/>
                        </a>
                    </div>
                    <div className="col-md-2 border align-self-center" style={{height: 1}}></div>
                </div>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
                <div className="line-space"/>
            </div>
        </div>
    );
};

export default Main;

import React, {useState} from 'react';
import Download from '../assets/img/1x/download.svg'
import Arrow from '../assets/img/SVG/Arrow.svg'
let data = [
    {
        title: 'استاندارد',
        content: 'EN81-1'
    },
    {
        title: 'تعداد توقف',
        content: '10 توقف (قابل افزایش تا 26 توقف با افزودن برد گسترش)'
    },
    {
        title: 'سرعت آسانسور',
        content: 'حداکثر تا 1.6 m/s'
    },
    {
        title: 'سیستم Cancelling',
        content: 'حذف فرمان شاسی کابین با دو بار کلیک'
    },
    {
        title: 'سخنگو',
        content: 'MP3، چند زبانه (فارسی، ترکی، انگلیسی، عربی)'
    },
    {
        title: 'قابلیت افزودن UPS',
        content: 'دارد'
    },
    {
        title: 'حداکثر جریان موتور',
        content: '15 آمپر'
    },
    {
        title: 'ارتباط برد و درایو',
        content: 'یکپارچه (تکنولوژی Integrated)'
    },
    {
        title: 'انواع شاسی احضار',
        content: 'Full Collective Down Collective Selective Collective'
    },
    {
        title: 'کنترل درب',
        content: 'دو درب مجزا (بدون سنسور اضافه)'
    },
    {
        title: 'روش کنترل',
        content: 'Open loop Close loop'
    },
    {
        title: 'کنترل گروهی',
        content: 'ندارد'
    },
    {
        title: 'توان درایو',
        content: '7.5kw'
    },
    {
        title: 'نوع موتور',
        content: 'موتور گیربکس (القایی)'
    }
]
let dataUPS = [
    {
        title: 'سازگاری',
        content: 'سازگار با تابلوفرمان داتیس مدل Light با درایو L100 با توان 7.5 kW'
    },
    {
        title: 'مصرف انرژی',
        content: '2 عدد باتری 12V - 7.2Ah، بدون نیاز به نگهداری و دشارژ ماهانه، دارای سیستم شارژ شناور جهت افزایش عمر باتری ها'
    },
    {
        title: 'پخش پیام',
        content: 'چند زبانه فارسی، ترکی، عربی و انگلیسی جهت حفظ خونسردی مسافرانm/s'
    }
]
let technical = [
    {
        title: 'Auto Leveling',
        content: 'قرار گیری خودکار کابین در سطح طبقه بعد از انجام سرویس'
    },
    {
        title: 'حفاظت',
        content: 'کارکرد آسانسور در صورت جابجایی فاز - سیستم کنترل فاز دیجیتالی مقاوم در برابر تغییرات ناگهانی ولتاژ خط - امکان بازیابی 16 خطای ثبت شده نهایی'
    },
    {
        title: 'هوشمندی',
        content: 'هماهنگی کامل سیستم کنترل با سیستم VVVF - افزایش سرعت پاسخ دهی آسانسور- حرکت بسیار نرم و بهینه بواسطه اطلاع کامل درایو از موقعیت آسانسور - حفظ دقیق موقعیت کابین حتی در صورت قطع برق در حین حرکت'
    },
    {
        title: 'Short Floor',
        content: 'امکان تعریف نیم طبقه برای طبقاتی با فاصله کوتاه'
    },
    {
        title: 'قابلیت های نرم افزاری',
        content: 'امکان مسدود کردن هر کدام از شاسی های احضار - دور اندازی در هر طبقه با پرچم اول و دوم به صورت مستقل- امکان تعریف طبقه پارک و عملکرد در فاز آتش نشانی - قابلیت جابجایی ترمینال های شاسی ها و نمراتور ها بر روی ترمینال های رزرو'
    },
    {
        title: 'جعبه رویزیون',
        content: 'کارکرد با تراول کابل 12 رشته-دارای کابل سوکتی شاسی کابین به همراه سیم های رنگی جهت سهولت اتصال(کلیه سیم کشی ها سمت جعبه رویزیون انجام شده است.'
    }
]
let drive = [
    {
        title: 'حرکت',
        content: 'حرکتی بدون لرزش و و ضربه هنگام شروع و پایان حرکت - هماهنگی کامل با برد کنترل با استفاده از تکنولوژی Integrated'
    },
    {
        title: 'Auto Learning',
        content: 'اندازه گیری پارامترهای موتور در هر بار استارت - بهره مندی از تابعAuto Tune هوشمند - استخراج مدل دقیق موتور'
    },
    {
        title: 'مخصوص آسانسور',
        content: 'دارای فرآیند کامل ترمز به همراه پارامترهای مربوطه - دارای ورودی مخصوص مدار ایمنی آسانسور و امکان دریافت فیدبک آن'
    },
    {
        title: 'Distance Approach',
        content: 'کمینه کردن زمان پیاده روی و همسطح سازی دقیق کابین از طریق تنظیم فاصله ی سنسور دورانداز تا ایست طبقه و همچنین طول سنسور ایست'
    }
]
const MultiTabs = ({content, title}) => {
    const [index, setIndex] = useState(0)
    const [show, setShow] = useState(-1)

    const toggleShow = (i) => {
        if (i === show) {
            setShow(-1)
        } else {
            setShow(i)
        }
    }

    return (
        <div className={'MultiTabs'}>
            <div className="tabs">
                {
                    title.map((v, i) => {
                        return <div key={i} className={"tab " + (index === i ? 'tab-active' : '')}
                                    onClick={() => {
                                        setShow(-1)
                                        setIndex(i)
                                    }}>

                            <span>{v}</span>
                        </div>
                    })
                }
            </div>
            {
                content === 'ControlPanel' ?

                    <div className="MultiTabs-box">
                        {index === 0 &&
                        <>
                            <div className="box-row">
                                {
                                    data.map((v, i) => {
                                        return <div key={i} className="box-row-tab">
                                            <div className="box-row-title">
                                                <span>{v.title}</span>
                                                <span>:</span>
                                            </div>
                                            <div className="box-row-content">
                                                <span>{v.content}</span>
                                            </div>
                                        </div>
                                    })
                                }

                            </div>

                        </>
                        }
                        {index === 1 &&
                        <>
                            <div className="box-row-D">
                                <a href={'https://beta55.datis-elevator.ir/Home/DownLoadFile?fileName=User%20Manual%20V.18(1).pdf'}>
                                    <img width={20} src={Download} alt=""/>
                                    <span>
                                استاندارد (+ CE اصلی زیما)
                            </span>
                                </a>
                                <a href={'https://beta55.datis-elevator.ir/Home/DownLoadFile?fileName=User%20Manual%20Light.pdf'}>
                                    <img width={20} src={Download} alt=""/>
                                    <span>
                                راهنمای نصب
                            </span>
                                </a>
                                <a href={'https://beta55.datis-elevator.ir/Home/DownLoadFile?fileName=User%20Manual%20V.18(1).pdf'}>
                                    <img width={20} src={Download} alt=""/>
                                    <span>
                                نقشه فنی

                            </span>
                                </a>
                                <a href={'https://beta55.datis-elevator.ir/Home/DownLoadFile?fileName=Persian%20Catalouge-compressed.pdf'}>
                                    <img width={20} src={Download} alt=""/>
                                    <span>
                                کاتالوگ محصول

                            </span>
                                </a>
                            </div>
                        </>
                        }

                        {index === 2 &&
                        <div className={'w-100'}>
                            {
                                technical.map((v, i) => {
                                    return <div key={i} className="MultiTabs-drop-down">
                                        <div className="MultiTabs-drop-down-title justify-content-between" onClick={() => toggleShow(i)}>
                                            <span>{v.title}</span>
                                            <span>
                                        <img  width={10} height={10} src={Arrow} alt=""/>
                                    </span>
                                        </div>
                                        <div
                                            className={"MultiTabs-drop-down-content " + (show === i ? 'MultiTabs-drop-down-content-show' : 'MultiTabs-drop-down-content-hide')}>
                    <span className={'text-black'}>
                        {v.content}
                    </span>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        }

                        {index === 3 &&
                        <div className={'w-100'}>
                            {
                                drive.map((v, i) => {
                                    return <div key={i} className="MultiTabs-drop-down">
                                        <div className="MultiTabs-drop-down-title justify-content-between" onClick={() => toggleShow(i)}>
                                            <span>{v.title}</span>
                                            <span>
                                        <img  width={10} height={10} src={Arrow} alt=""/>
                                    </span>
                                        </div>
                                        <div className={"MultiTabs-drop-down-content " + (show === i ? 'MultiTabs-drop-down-content-show' : 'MultiTabs-drop-down-content-hide')}>
                    <span className={'text-black'}>
                        {v.content}
                    </span>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        }

                    </div>

                    :

                    <div className="MultiTabs-box">
                        {index === 0 &&
                        <>
                            <div className="box-row">
                                {
                                    dataUPS.map((v, i) => {
                                        return <div key={i} className="box-row-tab">
                                            <div className="box-row-title">
                                                <span>{v.title}</span>
                                                <span>:</span>
                                            </div>
                                            <div className="box-row-content">
                                                <span>{v.content}</span>
                                            </div>
                                        </div>
                                    })
                                }

                            </div>

                        </>
                        }
                    </div>

            }



        </div>
    );
};

export default MultiTabs;

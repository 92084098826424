import React, {useEffect, useRef, useState} from 'react';
import {DeleteItemAdmin, GetAdminOrders, GetEx, InrepresentativeAdmin, RepresentativeId, Separator, Type} from "../api";
import {toast} from "react-toastify";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import {MultiSelect} from 'primereact/multiselect';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';


let type = '';
const selectItems = [{label: 'تحویل داده شده', value: 'delivered'}, {
    label: 'پرداخت موفق',
    value: 'successfulpayment'
}, {label: 'تحویل نماینده', value: 'inrepresentativestock'}, {
    label: 'پرداخت نشده',
    value: 'unsuccessfulpayment'
}, {label: 'کنسل شده', value: 'cancelled'},];

const Admin = () => {
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(['delivered', 'successfulpayment', 'inrepresentativestock', 'unsuccessfulpayment', 'cancelled']);
    const [open, setOpen] = useState(-1);
    const [filterDate, setFilterDate] = useState([]);
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: 0, to: 0
    });
    const notify = (e) => toast.success(e);
    const error = (e) => toast.error(e);
    console.log(selectedDayRange)
    const selector = (v) => {
        if (v) {
            setFilter(v)
        }
    }

    function download(blob, filename) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    const excel = () => {
        GetEx().then((r) => {
            download(r, 'chineh.csv')
            console.log(r, 'yyy')
        })
    }
    useEffect(() => {
        Type().then((r) => {
            console.log(r)
        })
        GetAdminOrders(type).then((response) => {
            if (response.status === 200) {
                setData(response.data)
            }
            console.log(response)
        })
    }, [])
    const sendToServer = (d, s, id) => {
        if ((d === 'cip' || d === 'fca') && s === 'successfulpayment') {
            InrepresentativeAdmin(id, {representativeId: RepresentativeId}).then((response) => {
                if (response.status === 200 && response.validationResult.errors.length === 0) {
                    notify('ثبت شد')
                    GetAdminOrders().then((response) => {
                        if (response.status === 200) {
                            setData(response.data)
                        }
                        console.log(response)
                    })
                } else if (response.validationResult.errors.length > 0) {
                    error('مشکل در اتصال به اینترنت ')
                } else {
                    error('مشکل در ارتباط با سرور')
                }
            })
        } else if (d === 'exw' && s === 'successfulpayment') {

        } else if (s === 'unsuccessfulpayment') {

        }

    }

    const deleteItem = (id) => {
        DeleteItemAdmin(id).then((response) => {
            if (response.status === 200) {
                notify('کنسل شد')
                GetAdminOrders().then((response) => {
                    if (response.status === 200) {
                        setData(response.data)
                    }
                    console.log(response)
                })
            }
        })
    }
    const StateButton = (v) => {
        if (!!v.licence) {
            switch (v.licence.status) {
                case "editable":
                    return 'ارسال اطلاعات';
                    break;
                case "accepted":
                    return 'تایید شده';
                    break;
                case "awaitingvalidation":
                    return 'در حال بررسی';
                    break;
                case "rejected":
                    return 'رد شده';
                    break;
                case "new":
                    return 'ارسال اطلاعات';
                    break;
                default:
                    return '';
            }
        } else if (!!v.product && v.product.type === "ControlPanel") {
            return 'ارسال اطلاعات';
        } else if (!!v.product && v.product.type === "UninterruptiblePowerSupply") {
            return 'UPS';
        }

    }
    // function getDatesInRange(startDate, endDate) {
    //     const date = new Date(startDate.getTime());
    //
    //     const dates = [];
    //
    //     while (date <= endDate) {
    //         dates.push(new Date(date));
    //         date.setDate(date.getDate() + 1);
    //     }
    //
    //     return dates;
    // }
    const handleTime = (b) => {
        if (!b){
            return 'b'
        }
        if (!!selectedDayRange.from && !!selectedDayRange.to) {
            const from = (selectedDayRange.from.year) + '/' + (selectedDayRange.from.month > 9 ? selectedDayRange.from.month : ('0' + selectedDayRange.from.month)) + '/' + (selectedDayRange.from.day > 9 ? selectedDayRange.from.day : '0' + selectedDayRange.from.day);
            const to = (selectedDayRange.to.year) + '/' + (selectedDayRange.to.month > 9 ? selectedDayRange.to.month : ('0' + selectedDayRange.to.month)) + '/' + (selectedDayRange.to.day > 9 ? selectedDayRange.to.day : ('0' + selectedDayRange.to.day));
            const date = new Date(b);
            // console.log(from ,  to)
            const d1 = new Date(from);
            const d2 = new Date(to);
            // console.log(getDatesInRange(d1, d2));
            if (date >= d1 && date <= d2) {
                // console.log('✅ date is between the 2 dates');
                return true
            } else {
                // console.log('⛔️ date is not in the range');
                return false
            }
        } else {
            return 'nothing'
        }

    }
    // handleTime()
    const StateButtonAdmin = (d, s) => {
        if ((d === 'fca' || d === 'cip') && s === 'successfulpayment') {
            return 'تحویل به نماینده'
        } else if (d === 'exw' && s === 'successfulpayment') {
            return 'تحویل درب کارخانه'
        } else if (s === 'unsuccessfulpayment' || s === 'submitted') {
            return 'پرداخت نشده'
        } else if (s === 'inrepresentativestock') {
            return 'موجود در انبار نماینده'
        } else if (s === 'cancelled') {
            return 'کنسل شد'
        } else if (s === 'delivered') {
            return 'تحویل شد'
        } else {
            return ''
        }
    }
    return (<div className={'admin project-agent'}>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="title w-100">
                <h5 className={'d-flex justify-content-between align-items-center'}> پنل مدیریت <span onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                }}>خروج از حساب کاربری</span>
                </h5>
            </div>
            <div className="d-flex w-100 flex-wrap align-self-center justify-content-around" style={{height: "auto"}}>
                <MultiSelect display="chip" value={filter} options={selectItems} onChange={(e) => selector(e.value)}/>

                {/*<select name="" id="select" ref={select}  onClick={() => selector()}>*/}
                {/*    <option value="delivered">تحویل داده شده</option>*/}
                {/*    <option value="successfulpayment">پرداخت موفق</option>*/}
                {/*    <option value="inrepresentativestock">تحویل نماینده</option>*/}
                {/*    <option value="unsuccessfulpayment">پرداخت نشده</option>*/}
                {/*    <option value="cancelled">کنسل شده</option>*/}
                {/*    <option value="" selected="selected">همه</option>*/}
                {/*</select>*/}
                {/*<a href="https://chineh.ir/chinehapi/api/v1/reports/orders">*/}

                {/*</a>*/}

                <div className="calender">
                    <DatePicker
                        value={selectedDayRange}
                        onChange={setSelectedDayRange}
                        // shouldHighlightWeekends
                        locale="fa"
                        inputPlaceholder="بازه زمانی را انتخاب کنید"
                    />
                </div>

                <div onClick={() => excel()} className="bottom">خروجی اکسل</div>
            </div>

            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            {!!data && data.length > 0 && data.filter(e => (filter.find((val) => e.status === val) && handleTime(e.successfulPaymentDate) !== 'b' && handleTime(e.successfulPaymentDate) === 'nothing') || (filter.find((val) => e.status === val) && handleTime(e.successfulPaymentDate) === true && handleTime(e.successfulPaymentDate) !== 'b')).map((v, index) => {
                return <div key={index} className="admin-order mb-2"
                            onClick={() => index === open ? setOpen(-1) : setOpen(index)}>
                    {(v.status === 'drafted' || v.status === 'submitted' || v.status === 'unsuccessfulpayment' || v.status === 'successfulpayment') &&
                        <span className={'cursor-p pr-1 blue-color'} style={{width: 15}}
                              onClick={() => deleteItem(v.id)}>X</span>}

                    {/*{v.orderItems.filter(val => val.product.type !== 'ControlPanel').map((val, i) => {*/}
                    {/*    return <div key={i}>*/}
                    {/*        <div className="d-flex  p-2 justify-content-between flex-wrap">*/}
                    {/*            <span>{val.product.type}</span>*/}
                    {/*            <p className={'pl-3 mb-0'}>*/}
                    {/*                /!*<span>وضعیت سفارش  :  </span>*!/*/}
                    {/*                /!*<span className='fs-8 mb-0'> {StateButton(val)} </span>*!/*/}
                    {/*                {val.units > 1 && <>*/}
                    {/*                    /!*<span>  Total :</span>*!/*/}
                    {/*                    <span>{Separator((1 - val.discount / 100) * val.unitPrice * val.units)}</span>*/}
                    {/*                    <span> = </span>*/}
                    {/*                </>}*/}

                    {/*                <span*/}
                    {/*                    style={{marginLeft: 20}}>{Separator((1 - val.discount / 100) * val.unitPrice) + '  ×  ' + val.units}</span>*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*})}*/}

                    {/*{v.orderItems.filter(val => val.product.type === 'ControlPanel').length > 0 && <>*/}
                    {/*    /!*{v.orderItems.filter(val => val.product.type === 'ControlPanel').length}*!/*/}
                    {/*    <div className="d-flex  p-2 justify-content-between flex-wrap">*/}
                    {/*        <span>{v.orderItems[0].product.type}</span>*/}
                    {/*        {v.deliveryCost > 0 && <p>*/}
                    {/*            <span>هزینه ارسال :</span>*/}
                    {/*            <span>{Separator(v.deliveryCost)}</span>*/}
                    {/*        </p>}*/}
                    {/*        <p className={'pl-3 mb-0'}>*/}
                    {/*            /!*<span>وضعیت سفارش  :  </span>*!/*/}
                    {/*            /!*<span className='fs-8 mb-0'> {StateButton(val)} </span>*!/*/}
                    {/*            {v.orderItems.filter(val => val.product.type === 'ControlPanel').length > 1 && <>*/}

                    {/*                /!*<span>  Total :</span>*!/*/}

                    {/*                /!*<span>{Separator((1 - v.orderItems[0].discount / 100) * v.orderItems[0].unitPrice * v?.orderItems.filter(val => val.product.type === 'ControlPanel').length)}</span>*!/*/}
                    {/*                <span>*/}
                    {/*                                   {v.orderItems[0].isPercentDiscount ? Separator((1 - v.orderItems[0].discount / 100) * v.orderItems[0].unitPrice * v?.orderItems.filter(val => val.product.type === 'ControlPanel').length) : Separator((v.orderItems[0].unitPrice - v.orderItems[0].discount) * v?.orderItems.filter(val => val.product.type === 'ControlPanel').length)}*/}
                    {/*                               </span>*/}
                    {/*                <span> = </span>*/}
                    {/*            </>}*/}
                    {/*            <span*/}
                    {/*                style={{marginLeft: 20}}>{v.orderItems[0].isPercentDiscount ? Separator((1 - v.orderItems[0].discount / 100) * v.orderItems[0].unitPrice) + '  ×  ' + v?.orderItems.filter(val => val.product.type === 'ControlPanel').length : Separator(v.orderItems[0].unitPrice - v.orderItems[0].discount)}</span>*/}
                    {/*        </p>*/}

                    {/*    </div>*/}
                    {/*</>}*/}


<div className="admin-table d-flex flex-wrap">
    <div className=""><span>{v.customer.firstName + ' ' + v.customer.lastName}</span></div>
    <div className="">
        {v.orderItems.filter(val => val.product.type !== 'ControlPanel').map((val, i) => {
            return <div key={i}>
                <div className="d-flex flex-column  pr-5 justify-content-between flex-wrap">
                    <span>{val.product.type}</span>
                    <p className={'mb-0 text-center'}>
                        {val.units > 1 && <>
                            <span>{Separator((1 - val.discount / 100) * val.unitPrice * val.units)}</span>
                            <span> = </span>
                        </>}

                        <span
                            style={{marginLeft: 20}}>{Separator((1 - val.discount / 100) * val.unitPrice) + '  ×  ' + val.units}</span>
                    </p>
                </div>
            </div>
        })}
    </div>
    <div className=""> {v.orderItems.filter(val => val.product.type === 'ControlPanel').length > 0 && <>
        {/*{v.orderItems.filter(val => val.product.type === 'ControlPanel').length}*/}
        <div className="d-flex flex-column pr-5 justify-content-between flex-wrap">
            <span>{v.orderItems[0].product.type}</span>

            <p className={'text-center mb-0'}>
                {v.orderItems.filter(val => val.product.type === 'ControlPanel').length > 1 && <>

                    {/*<span>  Total :</span>*/}

                    {/*<span>{Separator((1 - v.orderItems[0].discount / 100) * v.orderItems[0].unitPrice * v?.orderItems.filter(val => val.product.type === 'ControlPanel').length)}</span>*/}
                    <span>
                                                       {v.orderItems[0].isPercentDiscount ? Separator((1 - v.orderItems[0].discount / 100) * v.orderItems[0].unitPrice * v?.orderItems.filter(val => val.product.type === 'ControlPanel').length) : Separator((v.orderItems[0].unitPrice - v.orderItems[0].discount) * v?.orderItems.filter(val => val.product.type === 'ControlPanel').length)}
                                                   </span>
                    <span> = </span>
                </>}
                <span >{v.orderItems[0].isPercentDiscount ? Separator((1 - v.orderItems[0].discount / 100) * v.orderItems[0].unitPrice) + '  ×  ' + v?.orderItems.filter(val => val.product.type === 'ControlPanel').length : Separator(v.orderItems[0].unitPrice - v.orderItems[0].discount)}</span>
            </p>

        </div>
    </>}</div>
    <div className="d-flex flex-column pr-5 text-center">
        {v.deliveryCost > 0 && <>
        <span>هزینه ارسال </span>
        <span>{Separator(v.deliveryCost)}</span>
    </>}
    </div>
</div>
                    <p className={'d-flex justify-content-center pt-3'} >
                        <span style={{borderBottom: '0.1px solid #000'}}>
                        Total: {Separator(v.totalPrice)}
                    </span>
                    </p>


                    <div className="openDrop" style={open === index ? {display: "flex"} : {display: 'none'}}>
                        <p className={'mb-0 mt-3 p-2 d-flex justify-content-between flex-wrap'}>
                            {/*<p>{!!v.successfulPaymentDate ? v.successfulPaymentDate : ''}</p>*/}
                            <span>{!!v.successfulPaymentDate ? v.successfulPaymentDate : ''}</span>
                            {/*<span className='fs-9 mb-0 '>  {!!v.address ? v.address.province.name + ', ' + v.address.line : (v.deliveryType === 'cip' ? 'تحویل از نمایندگی' : v.deliveryType === 'exw' ? 'تحویل از کارخانه' : '')} </span>*/}
                            <span>
                              {v.requireFormalInvoice ? 'فاکتور رسمی میخوام' : 'فاکتور رسمی نمیخوام'}
                          </span>
                            <span>{v.customer.phoneNumber}</span>
                        </p>
                        <p className={'d-flex  p-2 flex-wrap'}>
                            <span className={'pl-1'}> آدرس :  </span>
                             <span
                                 className='fs-9 mb-0 '>  {!!v.address ? v.address.province.name + ', ' + v.address.line : (v.deliveryType === 'cip' ? 'تحویل از نمایندگی' : v.deliveryType === 'exw' ? 'تحویل از کارخانه' : '')} </span>
                            {/*<span>{v.customer.firstName + ' ' + v.customer.lastName}</span>*/}
                            {/*<span>{v.customer.phoneNumber}</span>*/}
                        </p>
                        {/*<p className=' mt-1 '>*/}
                        {/*    <span>{v.deliveryType === 'fca' ? 'آدرس    : ' : ''}</span>*/}
                        {/*    {!!v.address ? v.address.line : ''}*/}

                        {/*</p>*/}
                        <div
                            className={"bottom mb-0 w-25 align-self-center " + ((v.status === 'successfulpayment' && (v.deliveryType === 'cip' || v.deliveryType === 'fca')) ? '' : v.status !== 'delivered' && 'button-noChange ') + (v.status === 'delivered' ? ' button-green ' : '')}>
                        <span
                            onClick={() => sendToServer(v.deliveryType, v.status, v.id)}>{StateButtonAdmin(v.deliveryType, v.status)}</span>
                        </div>
                    </div>
                </div>
            })}
        </div>);
};

export default Admin;

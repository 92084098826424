import React, {useEffect, useState} from 'react';
import Logo from "../assets/img/1x/logo.png";
import profile from "../assets/img/1x/Asset 6.png";
import IconMenu from "../assets/img/1x/icon-menu.png";
import IconBuy from "../assets/img/1x/icon-buy.png";
import {Link, NavLink, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {GetProduct, GetSubmitItemOrders} from "../api";

const MenuAdmin = () => {
    const data = useSelector(state => state);
    const [information, setInformation] = useState([])

    return (
        <div className="right">

            <div className="menu-row">
                <a href="/">
                    <div className="logo">
                        <img width={100} height={42} src={Logo} alt=""/>
                        <div className="line-space"/>
                        <p className='mb-0'>ﭘﻠﺘﻔﻮرم ﺳﻔﺎرش ﻣﺤﺼﻮﻻت آﺳﺎﻧﺴﻮری</p>
                        <div className="line-space"/>
                    </div>
                </a>
                <div className="line-space"/>
                {/*<Link to={'/dashboard/profile'}>*/}
                <div className="profile d-flex">
                    <img width={30} height={40} src={profile} alt=""/>
                    <div className="line-space"/>
                    <div className="information">
                        <p className='mb-1'>
                            {(!!data.login && !!data.login.firstName) ? data.login.firstName + '   ' + data.login.lastName : ''}
                        </p>
                        <p className='fs-8 mb-1'>
                            {(!!data.login && !!data.login.nationalId) ? data.login.nationalId : ''}
                        </p>
                    </div>


                </div>
                {/*</Link>*/}
                <div className="line-space"/>

                <NavLink to="/admin/home" className="m-menu menu-buy" activeClassName="selected">
                         <span>
                            <img src={IconBuy} alt=""/>
                           <span className=''>{(!!information.orderItems && information.orderItems.length > 0) ? information.orderItems.length : ''}</span>
                        </span>
                    <span> خانه</span>
                </NavLink>

                <NavLink to="/admin/serial" className="m-menu menu-buy" activeClassName="selected">
                         <span>
                            <img src={IconBuy} alt=""/>
                           <span className=''></span>
                        </span>
                    <span> سریال ها </span>
                </NavLink>
                <NavLink to="/admin/user" className="m-menu menu-buy" activeClassName="selected">
                         <span>
                            <img src={IconBuy} alt=""/>
                           <span className=''></span>
                        </span>
                    <span> یوزر ها </span>
                </NavLink>
            </div>
        </div>
    );
};

export default MenuAdmin;

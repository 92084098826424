import React from 'react';
import Home from "../assets/img/1x/Asset 7.png";

const CheckInformation = () => {
    return (
        <div className='project checkInformation'>
            <div className="list">
                <input type="checkbox" id="accept" name="accept"
                       value="accept"/>
                <div className="list-right">
                    <img width={85} height={85} src={Home} alt=""/>
                    <div className="list-column">
                        <p className='blue-color fs-5 fw-bolder'>پــروژه ساختمــانی داتیــس شمــاره </p>
                        <p className=' mb-0'>
                            پــروانه ساخت :
                            ۰۱۲۳۴۵۶۷۸۹
                        </p>
                        <p className=' mb-0'>
                            تعداد طبقات :
                            ۵ طبقه

                        </p>
                    </div>
                </div>
                <div className="list-left">
                    <p>
                        وضعیت :  بررسی مدارک
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CheckInformation;

import React, {useEffect, useState} from 'react';
import Positive from "../assets/img/SVG/positive.svg";
import ExampleDrive from "../assets/img/1x/example-drive_1.png";
import Upload from "../assets/img/SVG/uploadLicense.svg";
import Avatar from "../assets/img/1x/example-drive_1.png";
import Delete from "../assets/img/1x/delete.png";
import {GETOrders, Separator} from "../api";

const Historys = () => {
    const [upload, setUpload] = useState({})
    const [value, setValue] = useState(['', '', '']);
    const [information, setInformation] = useState([])
    useEffect(() => {
        GETOrders('?filters=status==drafted').then((response) => {
            if (response.status === 200) {
                setInformation(response.data)
            }

        })
    }, [])
    return (
        <div className='allOrders'>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="order-add">
                <img width={35} src={Positive} alt=""/>
                <div className="order-box">
                    <p className='mb-0'>
                        ﺑﺮای اﻓﺰودن ﺳﻔﺎرش ﺟﺪﯾﺪ از روی ﻣﺤﺼﻮﻻت در اﯾﻦ ﻗﺴﻤﺖ اﻗﺪام ﮐﻨﯿﺪ
                    </p>
                    <div className="order-status">
                        <div className="bottom"><span> افزودن سفارش جدید</span></div>
                    </div>
                </div>

            </div>

            <div className="line-space"/>
            <div className="line-space"/>

            <div className="shoppingList-box">
                {!!information[0] &&
                information.map((v, i) => {
                    return <div key={i} className="history-column">
                        {
                            v.orderItems.map((v, i) => {
                                return <div key={i} className="shoppingList-box-row">
                                    <div className="d-flex align-items-center justify-content-center flex-wrap">
                                        <img width={40} src={Avatar} alt=""/>

                                        <div className="d-flex flex-wrap" style={{marginRight: 25}}>
                                            {
                                                !!v.licence ?
                                                    <span>
                    ﺷﻤﺎره ﭘﺮواﻧﻪ : {Separator(v.licence.number)}
                    </span>
                                                    :
                                                    <span>UPS</span>
                                            }
                                            <span>
                                                        {Separator(v.totalPrice)} تومان
                               </span>
                                        </div>


                                    </div>

                                </div>
                            })
                        }
                        <div className="">
                            <div className="">
                                <div className="line-space"/>
                                <div className="line-space"/>
                                <p className='fs-9'>آدرس : خ امیر اباد کوچه دوم پلاک 2 </p>
                            </div>
                        </div>
                    </div>
                })
                }
            </div>

        </div>
    );
};

export default Historys;

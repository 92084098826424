import React, {useState} from 'react';
import Finger from "../assets/img/1x/Asset 6.png";

const Authentication = () => {
    const [error, setError] = useState(false)
    return (
        <div className='authentication'>
            {
                error &&
                <div className="authentication-error">
                    <div className="title-error">
                        <p className='orange-color fs-3'>
                            اخطار
                        </p>
                        <p className='fs-3'>
                            شما احراز هویت نشده اید
                        </p>

                        <div className='line-space'/>

                    </div>
                    <div className="box">

                        <div className='line-space'/>

                        <img width={80} src={Finger} alt=""/>

                        <div className='line-space'/>
                        <div className='line-space'/>

                        <p className='text-justify'>
                            برای استفاده از سامانه چینه باید احراز هویت شوید تا سامانه
                            از خریدار دست آخر بودن شما و بررسی مدارک ارسالی اطمینان
                            پیدا کند.
                        </p>
                        <div className='line-space'/>
                        <div className="form-group box-input">
                            <input id="form_name" type="text" name="name" className="form-control"
                                   placeholder="شماره تلفن همراه خود را وارد کنید" required="required"
                                   data-error="شماره تلفن همراه ضروریست."/>
                        </div>


                        <div className="bottom"><span>تکمیل اطلاعات</span></div>


                        <p className='mt-0 mb-0'>
                            اگر سوالی دارید با شماره زیر تماس برقرار نماید
                        </p>
                        <p className='mt-0 mb-0 blue-color' style={{unicodeBidi: 'plaintext'}}>+ ۹۸ ۹۱۵ ۱۱۱ ۲۲ ۳۳</p>
                    </div>
                </div>
            }
            {
                !error &&
                <div className="authentication">
                    <div className="title-a">
                        <div className="title-img-a pl-4">
                            <img width={80} src={Finger} alt=""/>
                        </div>
                        <div className="text-right">
                            <p className='orange-color fs-4'>
                                احراز هویت
                            </p>
                            <p className='fs-8'>
                                شما بار اول است که وارد میشوید.

                            </p>
                            <p className='fs-8'>
                                لطفــــا اطاعــــات را وارد نمـــاییــــد.
                            </p>
                        </div>


                        <div className='line-space'/>

                    </div>
                    <div className="box">

                        <div className='line-space'/>
                        <div className='line-space'/>
                        <div className='line-space'/>

                        <div className="box-input d-flex pl-1">
                            <div className="form-group ">
                                <input id="form_name" type="text" name="name" className="form-control"
                                       placeholder="نام" required="required"
                                       data-error="نام ضروریست."/>
                            </div>
                            <div className="form-group pr-1">
                                <input id="form_name" type="text" name="family" className="form-control"
                                       placeholder="نام خانوادگی" required="required"
                                       data-error="نام خانوادگی ضروریست."/>
                            </div>
                        </div>


                        <div className="form-group box-input">
                            <input id="form_name" type="tel" name="code" className="form-control"
                                   placeholder="کد ملی" required="required"
                                   data-error="کد ملی ضروریست."/>
                        </div>

                        <div className="form-group box-input">
                            <input id="form_name" type="tel" name="birthday" className="form-control"
                                   placeholder="تاریخ تولد" required="required"
                                   data-error="تاریخ تولد ضروریست."/>
                        </div>

                        <div className="form-group box-input">
                            <input id="form_name" type="text" name="father" className="form-control"
                                   placeholder="نام پدر" required="required"
                                   data-error="نام پدر ضروریست."/>
                        </div>

                        <div className="form-group box-input">
                            <input id="form_name" type="text" name="sex" className="form-control"
                                   placeholder="جنسیت" required="required"
                                   data-error="جنسیت ضروریست."/>
                        </div>


                        <div className="bottom"><span>تکمیل اطلاعات</span></div>


                        <p className='mt-0 mb-0'>
                            اگر سوالی دارید با شماره زیر تماس برقرار نماید
                        </p>
                        <p className='mt-0 mb-0 blue-color' style={{unicodeBidi: 'plaintext'}}>+ ۹۸ ۹۱۵ ۱۱۱ ۲۲ ۳۳</p>
                    </div>
                </div>

            }

        </div>
    );
};

export default Authentication;
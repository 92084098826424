import React, {useState} from 'react';
import Finger from '../assets/img/1x/finger.png'
import ErrorAlert from "./errorAlert";
import {FixNumbers, LoginApi} from "../api";
import {useHistory} from "react-router-dom";
import {getAnalytics, logEvent} from "firebase/analytics";

const Login = () => {
    const history = useHistory();
    const [information, setInformation] = useState('')
    const [error, setError] = useState('');
    const [load, setLoading] = useState(false);
    const analytics = getAnalytics();
    const sendToServer = () => {
        if (information.length === 11) {
            setLoading(true)
            let body = {
                phoneNumber: FixNumbers(information)
            }
            LoginApi(body).then((response) => {
                // history.replace({pathname: '/otp', state: information})
                if (response.status === 200) {
                    setLoading(false)
                    logEvent(analytics, 'login-chineh');
                    history.push({pathname: '/otp', state: {tel: information , type: 'login'}})
                }else {
                    setLoading(false)
                    setError(!!response.validationResult.errors[0] ? response.validationResult.errors[0].errorMessage  : 'شماره خود را به درستی وارد کنید')
                }
            }).catch(() => {
                setLoading(false)
            })
        } else {
            setError( 'لطفا شماره خود را به درستی وارد نمایید')
        }

    }
    return (
        <div className='register'>
            <div className="top-title">

            </div>
            <div className="title">
                <p>
                    پلتفرم خرید مستقیم تجهزات الکترونیک آسانسور
                </p>

                <div className='line-space'/>

            </div>
            <div className="box">

                <div className='line-space'/>

                <img width={80} src={Finger} alt=""/>

                <div className='line-space'/>
                <div className='line-space'/>

                <p className='text-justify'>
                     با وارد کردن
                       شماره تماس و ارسال کد تایید میتوانید از امکانات چینه استفاده
                    نمایید.
                </p>
                <div className='line-space'/>
                <div className="form-group box-input">
                    <input id="tel" type="tel" name="tel" className="form-control" aria-autocomplete={'tel'}
                           onChange={(e) => setInformation(e.target.value)}
                           placeholder="شماره تلفن همراه خود را وارد کنید" required="required"
                           onKeyDown={(e)=>  e.code === 'Enter' && !load && sendToServer()}
                    />
                </div>
                {/*<div className="form-group box-input">*/}
                {/*    <input id="form_name" type="tel" name="code" className="form-control"*/}
                {/*           placeholder="کد ملی خود را وارد کنید" required="required"*/}
                {/*           data-error="کد ملی ضروریست."/>*/}
                {/*</div>*/}

                <ErrorAlert text={error}/>

                <div className={"bottom " + (!load && information.length > 10  ? '' : 'no-active')}  onClick={() => !load && sendToServer()}><span>ورود به سامانه چینه</span></div>

                {/*<p className='align-items-center text-justify d-flex'>*/}
                {/*    <input type="checkbox" id="accept" name="accept" onClick={() => setCheckBox(!checkBox)}*/}
                {/*           value="accept"/>*/}
                {/*    <span className='pr-2 blue-color pl-1 '>قوانین و مقرارت سایت </span>*/}
                {/*    چینه را میپذیرم*/}
                {/*</p>*/}
                <p className='align-items-center text-justify'>
                    در صورتی که ثبت نام نکرده اید
                    <span className='pr-1 orange-color pl-1 cursor-p underLine'  onClick={()=> history.push('register')}>اینجا</span>
                    کلیک کنید.
                </p>
            </div>
        </div>
    );
};

export default Login;

import React, {useEffect, useState} from 'react';
import {GetSerials, PostSerialAdmin} from "../api";
import {toast} from "react-toastify";

const AdminSerial = () => {
    const [input, setInput] = useState('')
    const [serial, setSerial] = useState([])
    const [load, setLoad] = useState(false)
    const notify = (e) => toast.success(e);
    const error = (e) => toast.error(e);
    const sendToServer = () => {
        setLoad(true)
        let body = {
            number: input,
        }
        PostSerialAdmin(body).then((response) => {
            if (response.status === 200) {
                notify('ثبت شد')
                getSerial()
            } else {
                setLoad(false)
                error('مشکل در ارتباط با سرور')
            }
        })
    }
    const getSerial = () => {
        GetSerials('').then((response) => {
            setLoad(true)
            if (response.status === 200) {
                setLoad(false)
                setSerial(response.data)
            } else {
                setLoad(false)
                error('مشکل در ارتباط با سرور')
            }
        })
    }
    useEffect(() => {
        getSerial()
    }, [])
    return (
        <div className={'AdminSerial'}>
            <div className="title-admin">
                <input type="tel" className={'w-25'} onChange={(e) => setInput((e.target.value).toUpperCase())}
                       placeholder={'سریال'}
                       value={input}/>

                <div className={"bottom mb-0 align-self-center mr-3"}>
                    <span onClick={() => sendToServer()}>ارسال سریال</span>
                </div>
            </div>

            <p className={'mr-3'}>
                کل :
                {' '}
                {!!serial && serial.length}
                {' '}
                عدد
            </p>

            <p className={'mr-3'}>
                باقی مانده :
                {' '}
                {!!serial && serial.filter(e => !e.orderItem).length}
                {' '}
                عدد
            </p>
            <p className={'mr-3'}>
                استفاده شده :
                {' '}
                {!!serial && serial.filter(e => !!e.orderItem).length}
                {' '}
                عدد
            </p>

            <div className="body-admin">
                {!!serial &&
                serial.filter(e => !e.customer).map((v, i) => {
                    return <div
                        className={"serial-admin serial-admin-Used"}>
                        <span> {v.number.toUpperCase()}</span>
                    </div>
                })
                }
                {!!serial &&
                serial.filter(e => !!e.customer).map((v, i) => {
                    return <div
                        className={"serial-admin serial-admin-UnUsed"}>
                        <span> {v.number.toUpperCase()}</span>
                            <span>{v.customer.firstName + ' ' + v.customer.lastName}</span>
                    </div>
                })
                }

            </div>


        </div>
    );
};

export default AdminSerial;

import React, {useEffect, useState} from 'react';
import {GETRepresentatives, GetSerials, PostSerial, PostSerialCustomer, Separator} from "../api";
import {toast} from "react-toastify";

let toggle = false;
const ProjectRepresentative = () => {
    const notify = (e) => toast.success(e);
    const error = (e) => toast.error(e);
    const [information, setInformation] = useState([])
    const [dataItem, setDtaItem] = useState([])
    const [serial, setSerial] = useState([])
    const [indexs, setIndex] = useState(-1)
    const [licence, setLicence] = useState([])
    useEffect(() => {
        GetSerials('?Filters=orderItem==null').then((response) => {
            if (response.status === 200) {
                setSerial(response.data)
                setLicence(response.data)
            }
        })
        GETRepresentatives('status!=unsuccessfulpayment,status!=cancelled,status!=delivered').then((response) => {
            if (response.status === 200) {
                setInformation(response.data)
            }

        })
    }, [])
    const SendToServer = (id, v, status,i) => {
        if (v === 'cip') {
            SendToAddress(id, i)
        }
        if (v === 'fca') {
            if (status === 'inrepresentativestock') {
                customer(id, i)
            }
            if (status === 'delivering') {
                SendToAddress(id, i)
            }

        }
    }
    const customer = (id, i) => {
        let body = {
            items: !!dataItem[i] ? dataItem[i].filter(e => !!e) : []
        }
        PostSerial(id, body).then((response) => {
            if (response.status === 200) {
                GETRepresentatives('status!=unsuccessfulpayment,status!=cancelled,status!=delivered').then((response) => {
                    if (response.status === 200) {
                        setInformation(response.data)
                    }
                })
                notify('با موفقیت ثبت شد')
            } else {
                error('مشکل در ارتباط با سرور')
            }
        })
    }
    const SendToAddress = (v, i) => {
        let body = {
            items: !!dataItem[i] ? dataItem[i].filter(e => !!e) : []
        }
        PostSerialCustomer(v, body).then((response) => {
            if (response.status === 200) {
                GETRepresentatives('status!=unsuccessfulpayment,status!=cancelled,status!=delivered').then((response) => {
                    if (response.status === 200) {
                        setInformation(response.data)
                    }
                })
                notify('با موفقیت ثبت شد')
            }else {
                error('مشکل در ارتباط با سرور')
            }

        }).catch(e => console.log(e))
    }
    const status = (v) => {
        switch (v) {
            case 'drafted':
                return 'سبد خرید'
                break;
            case 'submitted':
                return 'در انتظار پرداخت'
                break;
            case 'successfulpayment':
                return 'پرداخت موفق و در حال ارسال'
                break;
            case 'unsuccessfulpayment':
                return 'پرداخت ناموفق'
                break;
            case 'inrepresentativestock':
                return 'موجود در انبار نماینده'
                break;
            case 'delivering':
                return 'تحویل مأمور ارسال'
                break;
            case 'cancelled':
                return 'لغو شده'
                break;
            case 'delivered':
                return 'تحویل شده'
                break;
        }

    }
    // console.log(dataItem,'000')
    const selectRow = (e, i, idItem, id, index) => {
        // let inputs = [...data];
        // inputs[ide] = {id: id, serialNumber: e.target.value, serialId: idItem}
        // setDta(inputs)
        // setIndex(-1)
        //
        //
        let inputss = [...dataItem];
        let inr = !!inputss[i] ? [...inputss[i]] : [];
        inr[index] = {id: id, serialNumber: e.target.value, serialId: idItem};
        inputss[i] = inr;
        setDtaItem(inputss);
        setIndex(-1);
        // console.log(inr, index);
    }

    const searchInput = (e, id, index, i) => {
        let Licence = serial.filter(v => v.number.includes(e.target.value));

        let inputss = [...dataItem];
        let inr = !!inputss[i] ? [...inputss[i]] : [];
        inr[index] = {
            id: id,
            serialNumber: e.target.value,
            serialId: !!Licence && Licence.length === 1 ? Licence[0].id : ''
        };
        inputss[i] = inr;
        setDtaItem(inputss);


        // let inputs = [...data];
        // inputs[index] = {
        //     id: id,
        //     serialNumber: e.target.value,
        //     serialId: !!Licence && Licence.length === 1 ? Licence[0].id : ''
        // }
        //
        // setDta(inputs)
        if (Licence && e.target.value.length > 2) {
            setLicence(Licence)
            setIndex(i)
        } else {
            setLicence(Licence)
        }
        if (Licence && e.target.value.length < 1) {
            setIndex(-1)
        }
    }
    const openSubInput = (i) => {
        if (toggle && i === indexs) {
            setIndex(-1)
            toggle = false
        } else {
            setIndex(i)
            toggle = true

        }
    }

    // const changeInput = (e, id, i) => {
    //     let input = [...data];
    //     input[i] = {id: id, serialNumber: e.target.value}
    //     setDta(input)
    // }
    return (
        <div className='project-agent'>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="title w-100">
                <h5 className={'d-flex justify-content-between align-items-center'}> پنل نمایندگی <span onClick={() => {
                    localStorage.clear()
                    window.location.reload()
                }}>خروج از حساب کاربری</span></h5>

            </div>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>

            {!!information && information.length > 0 &&
            information.map((val, i) => {
                return <div key={i} className="history-column list">
                    {!!val.orderItems &&
                    val.orderItems.map((v, index) => {
                        return <div key={index} className="shoppingList-box-row">
                            <div className="d-flex align-items-center justify-content-between flex-wrap w-100">
                                {/*<img width={40} src={Avatar} alt=""/>*/}

                                <div className="d-flex flex-wrap w-100 justify-content-between"
                                     style={{marginRight: 25, marginLeft: 25}}>
                                    {
                                        !!v.licence ?
                                            <div>
                                                {/*ﺷﻤﺎره ﭘﺮواﻧﻪ : {Separator(1)}*/}
                                                <div className="list-column mt-1 position-relative">
                                                    {/*<p className='blue-color fs-5 fw-bolder'>شماره سریال </p>*/}
                                                    <input type="text"
                                                           className={'w-100'}
                                                           onClick={() => openSubInput(i)}
                                                           value={(!!dataItem[i] && !!dataItem[i][index]) ? dataItem[i][index].serialNumber : ''}
                                                           placeholder={!!v.serial ? v.serial.number : 'شماره سریال دستگاه'}
                                                           disabled={!!v.serial ? true : false}
                                                           onChange={(e) => searchInput(e, v.id, index, i)}/>

                                                    {
                                                        i === indexs &&
                                                        <div className='sub-input'>
                                                            {
                                                                licence.map((val, is) => {
                                                                    return <span key={is}
                                                                                 onClick={() => selectRow({target: {value: val.number}}, i, val.id, v.id, index)}>{val.number}</span>
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                    {/*{data.length === 0 || data.filter(e => e.serialId.length > 0).length > 0 ?*/}
                                                    {/*    <span> </span>*/}
                                                    {/*    :*/}
                                                    {/*    <span> سریال بدرستی وارد نشده است</span>*/}

                                                    {/*}*/}
                                                    <p className=' mt-3'>
                                                        <span>پــروانه ساخت : </span>
                                                        <span>{v.licence.number}</span>

                                                    </p>
                                                    <span>
                                                    تعداد :
                                                 <span>  {v.units}</span>
                                                </span>
                                                </div>

                                            </div>
                                            :
                                            <>
                                                <span>UPS</span>
                                                <span>
                                                    تعداد :
                                                 <span>  {v.units}</span>
                                                </span>
                                            </>

                                    }
                                    <span>
                                                        {Separator(v.totalPrice)} تومان
                               </span>
                                    {/* <span style={{textAlign: "left"}}>
                                                    <span className={'w-s-4'}>وضعیت سفارش  :  </span>
                                                    <span className='fs-9 mb-0 w-s-4'> {!!val.address ? val.address.line : val.deliveryType} </span>
                                                </span>*/}

                                </div>


                            </div>

                        </div>
                    })
                    }
                    <div className="">
                        <div className="d-flex pr-4 p-2 justify-content-between flex-wrap">
                            {/*<div className="line-space"/>*/}
                            {
                                val.deliveryType === 'cip' ?
                                    <p className={'mb-0'}>

                                        <span
                                            className='fs-9 mb-0'>  {!!val.address ? val.address.line : val.deliveryType === 'cip' ? 'تحویل از نمایندگی' : 'تحویل از کارخانه'} </span>
                                    </p>
                                    :
                                    <p className=' mt-1 '>
                                        <span>{val.deliveryType === 'fca' ? 'آدرس    : ' : ''}</span>
                                        {!!val.address ? val.address.line : ''}

                                    </p>}
                            <p className={'d-flex justify-content-between p-2 flex-wrap'}>
                                <span className={'pl-2'}>{val.customer.firstName + ' ' + val.customer.lastName}</span>

                                <span>({val.customer.phoneNumber})</span>
                            </p>
                            <p className={'pl-3 mb-0'}>
                                <span>وضعیت سفارش  :  </span>
                                <span
                                    className='fs-8 mb-0'> {status(val.status)} </span>
                            </p>
                        </div>
                    </div>
                    {/*+ (data.filter(e => e.serialId.length > 0).length> 0 ? '' : 'button-noChange')*/}
                    <div
                        className={"bottom w-25 d-flex align-self-center " + (((!!dataItem[i] && dataItem[i].filter(e => e.serialId.length > 0).length > 0 ) || val.orderItems.filter(e => e.product.type === 'UninterruptiblePowerSupply').length === val.orderItems.length || val.status === 'delivering') ? '' : 'button-noChange')}
                        onClick={() => !!val.orderItems && ((!!dataItem[i] && dataItem[i].filter(e => e.serialId.length > 0).length > 0) || val.orderItems.filter(e => e.product.type === 'UninterruptiblePowerSupply').length === val.orderItems.length || val.status === 'delivering') && SendToServer(val.id, val.deliveryType, val.status, i)}>
                        <span>{val.deliveryType === 'fca' ? val.status === 'delivering' ? 'تحویل مشتری' : 'تحویل باربری' : 'تحویل مشتری'}</span>
                    </div>
                </div>
            })
            }


        </div>
    );
};

export default ProjectRepresentative;

import React, {useEffect, useState} from 'react';
import Positive from "../assets/img/SVG/positive.svg";
import Upload from "../assets/img/SVG/uploadLicense.svg";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {AddOrders, DeleteOrders, FixNumbers, GETLicence, GETOrders, Platform, Separator} from "../api";
import Delete from "../assets/img/1x/delete.png";
import {toast} from "react-toastify";
import * as signalR from "@microsoft/signalr";
import Spinner from "../assets/img/SVG/Spinner.svg";
import ScannerIcon from '../assets/img/PNG/barcode.png';
import CountDown from "./countDown";
import Drive from "../assets/img/1x/Drive-Small.png";
import Ups from "../assets/img/1x/UPS.png";
import Scanner from "./scanner/Scanner";
import {URL} from '../api'
import {getAnalytics, logEvent} from "firebase/analytics";

let toggle = false;
let formData = new FormData();
let allLicence = []
let lastId = -1;

const connection = new signalR.HubConnectionBuilder().withUrl(URL + 'hub', {accessTokenFactory: () => localStorage.getItem('TOKEN')}).withAutomaticReconnect().build();
const AllOrders = () => {
        const history = useHistory()
        const dispatch = useDispatch()
        const data = useSelector(state => state)
        const [totalDrive, setTotalDrive] = useState([])
        const [loading, setLoading] = useState(false)
        const [scans, setScans] = useState({show: false, index: -1})
        const [index, setIndex] = useState(-1)
        const [licence, setLicence] = useState(allLicence)
        const [licenceEnter, setLicenceEnter] = useState({input: [], id: []})
        const analytics = getAnalytics();
        const GetDataSignalR = () => {
            connection.on("LicenceStatusChanged", dataSignalR => {
                let message = dataSignalR;
                console.log(dataSignalR)
                // if (!!document.getElementsByClassName('l-' + message.id).filter((v)=> v.classList[0])) {
                //     console.log('--------')
                // }
                try {
                    let i = 0
                    for (i in document.getElementsByClassName('l-' + message.id)) {
                        if (!!document.getElementsByClassName('l-' + message.id)[i].classList[0] && document.getElementsByClassName('l-' + message.id)[i].classList[0] === 'l-' + message.id) {
                            document.getElementsByClassName('l-' + message.id)[i].classList.remove('button-red')
                            document.getElementsByClassName('l-' + message.id)[i].classList.remove('button-orange')
                            document.getElementsByClassName('l-' + message.id)[i].classList.add(message.status === 'accepted' ? 'button-orange' : 'button-red')
                            document.getElementsByClassName('l-' + message.id)[i].querySelector(' span').innerHTML = StateButton({licence: {status: message.status}})
                        }
                    }
                } catch (e) {
                    // console.log(e)
                }


            })
        }


        const handleVisibilityChange = () => {
            if (connection.state === 'Disconnected' && !!connection && !!localStorage.getItem('ID')) {
                connection.start().then(() => {
                    console.log('handleVisibilityChange')
                }).then(() => {
                    GetDataSignalR()
                }).catch((e) => {
                    console.log(e, 'c')
                })
            }
            try {
                connection.onreconnected(connectionId => {
                    console.log('reconnected', connectionId);
                });
            } catch (e) {
                console.log(e, 're')
            }
        }

        useEffect(() => {
            handleVisibilityChange()
        }, [connection])

        useEffect(() => {
            logEvent(analytics, 'add-product-chineh');
        }, [data.product])

        useEffect(() => {

            // document.addEventListener('keydown', logKey);
            //
            // function logKey(e) {
            //     // e.preventDefault();
            //     console.log(e.code >= 65 && e.code <= 122)
            //     console.log(e)
            //
            // }
            GETOrders('?filters=status==drafted').then((response) => {
                if (response.status === 200) {
                    // g = response.data[0].orderItems
                    dispatch({
                        type: 'product',
                        product: !!response.data ? response.data : []
                    })
                }

            })

            GETLicence().then((response) => {
                // setLicence(response.data)
                allLicence = response.data
                setLicence(allLicence)
            })
        }, [])


        const notify = (e) => toast.success(e);
        const error = (e) => toast.error(e);

        const SendInformationToServer = async (i, OrderItemId, status, id, v) => {
            if (((!!data.product[0] && !!data.product[0].orderItems[i].img && licenceEnter.input[i].length >= 5) && status === '') || licenceEnter.id[i] === i) {
                formData = new FormData();
                formData.append("Number", v.product.type === 'ControlPanel' ? FixNumbers(licenceEnter.input[i]) : null);
                formData.append("Image", v.product.type === 'ControlPanel' ? data.product[0].orderItems[i].file : null);

                setLoading(true)
                AddOrders(formData, OrderItemId).then((response) => {
                    if (response.status === 200) {
                        setLoading(false)
                        GETOrders('?filters=status==drafted').then((response) => {
                            if (response.status === 200) {
                                let count = data.product
                                count[0].orderItems[i] = response.data[0].orderItems.filter(v => v.id === OrderItemId)[0]
                                dispatch({
                                    type: 'product',
                                    product: count
                                })
                            }
                        })
                    } else {
                        setLoading(false)
                        error(!!response.validationResult.errors[0] ? response.validationResult.errors[0].errorMessage : 'شماره خود را به درستی وارد کنید')
                    }
                })
            } else if ((!!data.product[0].orderItems[i]) || (!!licenceEnter.input[i] && licenceEnter.input[i].length < 5)) {
                error('اطلاعات را به درستی وارد کنید')
            }
        }

        const selectRow = (e, i) => {
            let input = {...licenceEnter};
            input.input[i] = e.target.value;
            input.id[i] = i;
            setLicenceEnter({input: input.input, id: input.id});
            setIndex(-1);
        }

        const searchInput = (e, i) => {
            let Licence = allLicence.filter(v => v.number.includes(e.target.value));
            if (Licence && e.target.value.length > 2) {
                setLicence(Licence)
                setIndex(i)
            } else {
                setLicence(allLicence)
            }
            if (Licence && e.target.value.length < 1) {
                setIndex(-1)
            }
            let input = {...licenceEnter};
            input.input[i] = e.target.value;
            input.id[i] = -1
            setLicenceEnter({input: input.input, id: input.id});
        }

        const openSubInput = (i) => {
            if (toggle && i === index) {
                setIndex(-1)
                toggle = false
            } else {
                setIndex(i)
                toggle = true
            }
        }
        // console.log(data.product)

        const submit = (id) => {
            if (!!data.product[0].orderItems && data.product[0].orderItems.filter(e => !!e.licence || e.product.type === 'UninterruptiblePowerSupply').length > 0) {
                history.push('/dashboard/shoppingList')
                // if (data.product[0].orderItems.filter(e => !e.licence).length > 0) {
                //     error('فقط سفارش های تکمیل شده به صفحه پرداخت منتقل میشوند')
                // }
                logEvent(analytics, 'notification_received');
            } else {
                error('لطفا اطلاعات مورد نیاز را پر کنید')
            }

        }

        const StateButton = (v) => {
            if (!!v.licence) {
                switch (v.licence.status) {
                    case "editable":
                        return 'ارسال اطلاعات';
                        break;
                    case "accepted":
                        return 'تایید شده';
                        break;
                    case "awaitingvalidation":
                        return 'در حال بررسی';
                        break;
                    case "rejected":
                        return 'رد شده';
                        break;
                    case "new":
                        return 'ارسال اطلاعات';
                        break;
                    default:
                        return '';
                }
            } else if (v.product.type === "ControlPanel") {
                return 'ارسال اطلاعات';
            } else {
                return 'تایید شده';
            }

        }
        const DeleteItem = (i, id, t) => {
            DeleteOrders(id).then(async (response) => {
                if (response.status === 200) {
                    notify('با موفقیت حذف شد')

                    GETOrders('?filters=status==drafted').then((response) => {
                        if (response.status === 200) {
                            let count = data.product
                            count[0].orderItems = data.product[0].orderItems.filter(v => v.id !== id)
                            count[0].orderItems.map((v, i) => v.licence === response.data[0].orderItems[i])
                            dispatch({
                                type: 'product',
                                product: count
                            })
                        }

                    })
                }
            })

        }
        const CreateDrive = () => {
            if (data.Drive > 0) {
                let array = []
                let i = 0;
                for (i = 0; i < data.Drive; i++) {
                    array.push('')
                }
                setTotalDrive([...totalDrive, ...array]);
            }
        }
        const uploadImg = (e, i) => {
            dispatch({
                type: 'product',
                product: data.product.map((v, f) => {
                        return {
                            ...v,
                            orderItems: v.orderItems.map((value, j) => {
                                    return value.product.type !== 'UninterruptiblePowerSupply' ? {
                                        ...value,
                                        'img': i === j ? !!e && e.name : !!value.img && value.img.length > 0 ? value.img : '',
                                        'file': i === j ? e : !!value.file ? value.file : ''
                                    } : {...value}

                                }
                            )
                        }
                    }
                )
            })
            logEvent(analytics, 'upload license-chineh');
        }
        const scan = async (e) => {
            if (!!e && e !== lastId) {
                lastId = await e;
                let id = await lastId.split('=')[1];
                if (!!id && id.trim() !== '') {
                    setScans({show: false, index: -1})
                    let input = {...licenceEnter};
                    input.input[scans.index] = id;
                    input.id[scans.index] = -1
                    setLicenceEnter({input: input.input, id: input.id});
                }
            } else {
                setTimeout(() => {
                    lastId = '';
                }, 2000)
            }
        }

        return (
            <>
                {
                    scans.show &&
                    <div className="scanner">

                        <Scanner
                            onError={(e) => setScans({index: -1, show: false})}
                            onScan={(e) => scan(e)}
                            refresh={(e) => console.log(e)}
                        />
                    </div>
                }
                <div className='allOrders'>


                    <div className="order-add">
                        <Link to={'/dashboard/product'}>
                            <img width={35} src={Positive} alt=""/>
                        </Link>

                        <div className="order-box">
                            <p className='mb-0'>
                                ﺑﺮای اﻓﺰودن ﺳﻔﺎرش ﺟﺪﯾﺪ از روی ﻣﺤﺼﻮﻻت در اﯾﻦ ﻗﺴﻤﺖ اﻗﺪام ﮐﻨﯿﺪ
                            </p>
                            <div className="order-status">
                                <Link to={'/dashboard/product'}>
                                    <div className="bottom"><span>مشاهده محصولات</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="line-space"/>
                    <div className="line-space"/>

                    {!!data.product && !!data.product[0] && !!data.product[0].orderItems && data.product[0].orderItems.length > 0 ?
                        data.product[0].orderItems.map((v, i) => {
                            return <div key={i} className="order">
                                <img width={20} src={Delete} className='delete-item' alt=""
                                     onClick={() => DeleteItem(i, v.id, v.remainingTime)}/>
                                <div className="d-flex align-items-center flex-wrap justify-content-center">
                                    {
                                        v.product.type === 'ControlPanel' ?

                                            <img width={90} height={85} src={Drive} className='ml-3 mb-1 p-1' alt=""/>
                                            :
                                            <>
                                                <div className="space"/>
                                                <img width={75} height={85} src={Ups} className='ml-3 mb-1 p-1' alt=""/>
                                            </>

                                    }

                                    {
                                        v.product.type === 'ControlPanel' ?
                                            <div
                                                className={"order-information " + ((Platform == 'Android' || Platform == 'iOS') ? 'order-information-left' : '')}>
                                                <p style={{fontSize: 12, color: !!v.licence ? '#F0F0F0' : '#7e0505'}}>برای
                                                    خرید محصول پر کردن اطلاعات زیر ضروری می باشد.</p>
                                                <input type="text" onChange={(e) => searchInput(e, i)}
                                                       onClick={() => openSubInput(i)}
                                                       placeholder={!!v.licence ? v.licence.number : 'ﺷﻤﺎره ﭘﺮواﻧﻪ ﺳﺎﺧﺖ را وارد ﮐﻨﯿﺪ'}
                                                       disabled={!!v.licence}
                                                       value={!!licenceEnter.input[i] ? licenceEnter.input[i] : ''}/>
                                                {
                                                    (!v.licence && (Platform == 'Android' || Platform == 'iOS')) &&
                                                    <span className={'scanner-icon'}
                                                          onClick={() => setScans({show: !scans.show, index: i})}>
                                                 <img width={25} height={28} src={ScannerIcon} alt=""/>
                                                </span>
                                                }

                                                {
                                                    i === index &&
                                                    <div className='sub-input'>
                                                        {
                                                            licence.map((v, index) => {
                                                                return <span key={index}
                                                                             onClick={() => selectRow({target: {value: v.number}}, i)}>{v.number}</span>
                                                            })
                                                        }
                                                    </div>
                                                }
                                                <div className="line-space"/>
                                                <div className="input-icon">
                                                    <label htmlFor={i}>
                                                        {
                                                            !v.licence &&
                                                            <img src={Upload} width={40} alt="" className={'cursor-p'}/>
                                                        }

                                                        <input type="text"
                                                               placeholder={!!v.img ? v.img : !!v.licence ? v.licence.image : ' ﭘﺮواﻧﻪ ﺳﺎﺧﺖ '}
                                                               disabled={!!v.licence}
                                                               value={licenceEnter.id[i] !== undefined && licenceEnter.id[i] === i ? 'عکس آپلود شده' : ''}
                                                               onChange={() => console.log('')}/>
                                                    </label>
                                                    <input type="file" id={i} className={'license-hide'} accept="image/*"
                                                           hidden={true}
                                                           disabled={(!!v.licence ? true : false) || (licenceEnter.id[i] === i ? true : false)}
                                                           onChange={(e) => uploadImg(e.target.files[0], i)}/>
                                                </div>
                                            </div>
                                            :
                                            <div className="input-icon">
                                                <p>UPS 1.2 KVA</p>
                                                {/*<span>تعداد <span className="space"/>   :  </span>*/}
                                                {/*<span className="space"/>*/}
                                                {/*<span> {v.units}</span>*/}
                                            </div>
                                    }
                                </div>

                                <div className="fee-order">
                                    {
                                        v.discount > 0 &&
                                        <span
                                            className={'price-line w-s-4'}> {Separator(v.unitPrice * v.units)} تومان </span>
                                    }

                                    <span className={'w-s-4'}>     {Separator(v.totalPrice)} تومان</span>
                                    <span className={'w-s-4'}>تعداد : {v.units}        </span>
                                </div>

                                <div className="order-status">
                                    {/*<p ref={el => Timer.current[i] = el} id={'h-' + v.id}>  {v.product.type === 'ControlPanel' ? CreatTime(v.remainingTime, v.id) : '---' }</p>*/}

                                    <p className={v.product.type !== 'ControlPanel' || v.remainingTime === 0 ? 'p-12' : ''}>
                                        {
                                            v.product.type === 'ControlPanel' ?
                                                <CountDown t={v.remainingTime} i={v.id}/>

                                                :
                                                'محدودیت زمانی ندارد'
                                        }

                                    </p>
                                    <p></p>
                                    <div
                                        className={(!!v.licence ? 'l-' + v.licence.id : '') + (!loading ? '' : ' button-noChange ') + " bottom mb-0 " + (!!v.licence ? (v.licence.status === "accepted" ? ' button-orange ' : v.licence.status === "rejected" ? ' button-red ' : v.product.type === "ControlPanel" ? ' button-noChange ' : ' button-orange ') : v.product.type === "ControlPanel" ? '' : ' button-orange')}
                                        onClick={() => {
                                            !loading && !v.licence && v.product.type === 'ControlPanel' && SendInformationToServer(i, v.id, !!v.licence ? v.licence.status : '', '', v, v.id)
                                        }}>
                                        <span>{StateButton(v)}</span>
                                    </div>
                                </div>
                            </div>
                        })
                        :
                        !data.product ?
                            <div className={'m-auto'}>
                                <img width={55} src={Spinner} alt=""/>
                            </div>

                            :
                            <div className="text-center d-flex justify-content-center flex-column align-items-center h-100">
                                <p>سفارشی ثبت نشده است.</p>
                                <p> برای افزودن سفارش لطفا از طریق منو کناری اقدام فرمایید.</p>
                            </div>
                    }

                    {
                        !!data.product && !!data.product[0] && data.product[0].orderItems.length > 0 &&
                        <>
                            <div className="line-space"/>
                            <div className="line-space"/>
                            <div
                                className={"bottom mb-0 "} onClick={() => submit(data.product[0].id)}>
                                <span>ادامه فرایند خرید</span>
                            </div>
                            <div className="line-space"/>
                            <div className="line-space"/>
                        </>

                    }
                </div>
            </>
        );
    }
;

export default AllOrders;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createStore} from "redux";
import {Provider} from "react-redux";
import reducer from "./redux/reducer";
import * as Sentry from "@sentry/react";
import {initializeFirebase} from "./firebase/push-notification";
const store = createStore(reducer);
// reportWebVitals(console.log);
initializeFirebase();
Sentry.init({
    dsn: "https://d677abcf8cac4658a8a606012cb7269d@o419517.ingest.sentry.io/6037254",
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


import React, {useState} from 'react';
import Safe from '../assets/img/1x/Asset 5.png'
import ErrorAlert from "./errorAlert";
import {FixNumbers, OtpApi, OtpApiR} from "../api";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import CountDown from "./countDown";

const Otp = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const sendToServer = () => {
        let body = {
            phoneNumber: FixNumbers(history.location.state.tel),
            verificationCode: FixNumbers(code),
        }
        if (!!history.location.state) {
            if (history.location.state.type === 'register') {
                OtpApiR(body).then((response) => {
                    // history.replace('/dashboard/project')
                    if (response.status === 200) {
                        localStorage.setItem('TOKEN', response.data.token)
                        localStorage.setItem('ID', response.data.id)
                        dispatch({type: 'login', startApp: true, login: response.data})
                        if (response.data.type === 'Customer') {
                            history.replace('/dashboard/allOrders')
                        }else if(response.data.type === 'Admin'){
                            history.replace('/admin/home')
                        }else {
                            history.replace('/agent/home')
                        }

                    }else {
                        // dispatch({type: 'login', startApp: false})
                        setError(!!response.validationResult.errors[0] ? response.validationResult.errors[0].errorMessage  : 'کد را به درستی وارد نمایید')
                    }
                })
            }else if (history.location.state.type === 'login'){
                OtpApi(body).then((response) => {
                    // history.replace('/dashboard/project')
                    if (response.status === 200) {
                        localStorage.setItem('TOKEN', response.data.token)
                        localStorage.setItem('ID', response.data.id)
                        dispatch({type: 'login', startApp: true, login: response.data})
                        if (response.data.type === 'Customer') {
                            history.replace('/dashboard/allOrders')
                        }else if (response.data.type === 'Admin'){
                            history.replace('/admin/home')
                        }else {
                            history.replace('/agent/home')
                        }

                    }else {
                        // dispatch({type: 'login', startApp: false})
                        setError(!!response.validationResult.errors[0] ? response.validationResult.errors[0].errorMessage  : 'کد را به درستی وارد نمایید')
                    }
                })
            }
        } else {

        }
    }
    return (
        <div className='register'>
            <div className="top-title">

            </div>
            <div className="title">
                <p>
                    پلتفرم خرید مستقیم تجهزات الکترونیک آسانسور
                </p>
                <br/>
            </div>
            <div className="box">
                <img width={65} src={Safe} alt=""/>
                <p></p>
                <p className='text-justify'>
                    لطفا برای ورود کد ارسالی را وارد نمایید.
                </p>

                <div className="form-group box-input">
                    <input id="form_name" type="tel" name="code" className="form-control"
                           onChange={(e) => setCode(e.target.value)}
                           placeholder="کد را وارد نمایید" required="required"
                           onKeyDown={(e)=>  e.code === 'Enter' && sendToServer()}
                          />
                </div>
                <p> <span>زمان باقی مانده  :  </span>   <CountDown t={'120'} i={''}/> </p>
                <div className={"bottom " + (code.length > 5  ? '' : 'no-active')} onClick={() => sendToServer()}><span>ورود</span></div>
                <p className='mt-0 mb-0'>
                    اگر شماره را اشتباه وارد کردید یا پیامک دریافت نکردید
                </p>
                <ErrorAlert text={error}/>
                <p className='mt-0 mb-0 orange-color cursor-p underLine' onClick={()=> history.replace('/login')}>اینجا کلیک کنید.</p>
            </div>
        </div>
    );
};

export default Otp;

import React, {useEffect, useState} from 'react';

const CountDown = ({t, i}) => {
    const [time, setTime] = useState('کمی صبر کنید ...')
    useEffect(() => {
        let timestamp = t;

        function component(x, v) {
            return Math.floor(x / v);
        }

        let CreatTim = setInterval(() => {
            if (timestamp > 0) {
                timestamp--;
            }
            let minutes = component(timestamp, 60) % 60,
                seconds = component(timestamp, 1) % 60;
            if (timestamp <= 0) {
                setTime('')
                clearInterval(CreatTim)
            } else {
                // if (!!document.querySelector('#h-' + i)) {
                // document.querySelector('#h-' + i).innerHTML = minutes + ":" + seconds
                setTime(minutes + ":" + seconds)
                // } else {
                //     clearInterval(CreatTim)
                // }
            }
        }, 1000);
        if (timestamp <= 0) {
            setTime('')
            clearInterval(CreatTim)
        }

        return () => {
            clearInterval(CreatTim)
        }
    }, [t])
    return (
        <>
            {time}
        </>
    );
};

export default CountDown;

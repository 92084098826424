let InitialState = {
    login: {},
    startApp: Boolean,
    Drive: [],
    UPS: 0,
    product: undefined,
    url: true
};
const reducer = (state = InitialState, action) => {
    switch (action.type) {
        case 'login':
            return {
                ...state,
                login: action.login,
                startApp: action.startApp,
            };
        case 'orders':
            return {
                ...state,
                Drive: action.Drive,
                UPS: action.UPS,
            };
        case 'product':
            return {
                ...state,
                product: action.product,
            };
        case 'ID':
            return {
                ...state,
                ID: action.ID,
            };
        case 'url':
            return {
                ...state,
                url: action.url,
            };
        default:
            return state;
    }
};
export default reducer;

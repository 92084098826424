import React, {useEffect, useState} from 'react';
import {GETRepresentatives, Separator} from "../api";

const HistoryRepresentative = () => {

    const [information, setInformation] = useState([])
    const [data, setDta] = useState([])
    useEffect(() => {
        GETRepresentatives('Representative!=null,status==delivered').then((response) => {
            if (response.status === 200) {
                setInformation(response.data)
            }
        })

    }, [])

    const status = (v) => {
        switch (v) {
            case 'drafted':
                return 'سبد خرید'
                break;
            case 'submitted':
                return 'در انتظار پرداخت'
                break;
            case 'successfulpayment':
                return 'پرداخت موفق و در حال ارسال'
                break;
            case 'unsuccessfulpayment':
                return 'پرداخت ناموفق'
                break;
            case 'inrepresentativestock':
                return 'موجود در انبار نماینده'
                break;
            case 'delivering':
                return 'تحویل مأمور ارسال'
                break;
            case 'cancelled':
                return 'لغو شده'
                break;
            case 'delivered':
                return 'تحویل شده'
                break;
        }

    }

    const changeInput = (e, id, i) => {
        let input = [...data];
        input[i] = {id: id, serialNumber: e.target.value}
        setDta(input)
    }
    return (
        <div className='project-agent'>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            <h4>تاریخچه فروش</h4>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            {!!information && information.length > 0 &&
            information.map((val, i) => {
                return <div key={i} className="history-column list">
                    {!!val.orderItems &&
                    val.orderItems.map((v, index) => {
                        return <div key={i} className="shoppingList-box-row">
                            <div className="d-flex align-items-center justify-content-between flex-wrap w-100">
                                {/*<img width={40} src={Avatar} alt=""/>*/}

                                <div className="d-flex flex-wrap w-100 justify-content-between"
                                     style={{marginRight: 25, marginLeft: 25}}>
                                    {
                                        !!v.licence ?
                                            <span>
                    {/*ﺷﻤﺎره ﭘﺮواﻧﻪ : {Separator(1)}*/}
                                                <div className="list-column mt-1">
                        {/*<p className='blue-color fs-5 fw-bolder'>شماره سریال </p>*/}
                                                    <input type="text"
                                                           value={!!data[i] ? data[i].serialNumber : ''}
                                                           placeholder={!!v.serialNumber ? v.serialNumber : 'شماره سریال دستگاه'}
                                                           disabled={ true }
                                                           onChange={(e) => changeInput(e, v.id, index)}/>
                        <p className=' mt-3'>
                            <span>پــروانه ساخت :</span>
                            <span>{v.licence.number}</span>

                        </p>
                                 <span>
                                                    تعداد :
                                                 <span>  {v.units}</span>
                                                </span>
                    </div>

                    </span>
                                            :
                                            <>
                                                <span>UPS</span>
                                                <span>
                                                    تعداد :
                                                 <span>  {v.units}</span>
                                                </span>
                                            </>

                                    }
                                    <span>
                                                        {Separator(v.totalPrice)} تومان
                               </span>
                                    {/* <span style={{textAlign: "left"}}>
                                                    <span className={'w-s-4'}>وضعیت سفارش  :  </span>
                                                    <span className='fs-9 mb-0 w-s-4'> {!!val.address ? val.address.line : val.deliveryType} </span>
                                                </span>*/}
                                </div>


                            </div>

                        </div>
                    })
                    }
                    <div className="">
                        <div className="d-flex pr-4 p-2 justify-content-between flex-wrap">
                            {/*<div className="line-space"/>*/}
                            {
                                val.deliveryType === 'cip' ?
                                    <p className={'mb-0'}>

                                        <span
                                            className='fs-9 mb-0'>  {!!val.address ? val.address.line : val.deliveryType === 'cip' ? 'تحویل از نمایندگی' : 'تحویل از کارخانه'} </span>
                                    </p>
                                    :
                                    <p className=' mt-1 '>
                                        <span>{val.deliveryType === 'fca' ? 'آدرس    : ' : ''}</span>
                                        {!!val.address ?  val.address.line : ''}

                                    </p>}


                            <p className={'pl-3 mb-0'}>
                                <span>وضعیت سفارش  :  </span>
                                <span
                                    className='fs-8 mb-0'> {status(val.status)} </span>
                            </p>

                        </div>
                    </div>
                    {/*<div className="bottom w-25 d-flex align-self-center"*/}
                    {/*     onClick={() => SendToServer(val.id, val.deliveryType, val.status)}>*/}
                    {/*    <span>{val.deliveryType === 'fca' ? val.status === 'delivering' ?  'تحویل مشتری' : 'تحویل باربری' : 'تحویل مشتری'}</span>*/}
                    {/*</div>*/}
                </div>
            })
            }


        </div>
    );
};

export default HistoryRepresentative;

import React, {useEffect, useState} from 'react';
import Logo from "./assets/img/1x/logo.png";
import profile from "./assets/img/1x/Asset 6.png";
import IconMenu from "./assets/img/1x/icon-menu.png";
import IconBuy from "./assets/img/1x/icon-buy.png";
import Negative from "./assets/img/SVG/negative.svg";
import Positive from "./assets/img/SVG/positive.svg";
import Spinner from "./assets/img/SVG/Spinner.svg";
import Drive from "./assets/img/1x/Drive-Small.png";
import Ups from "./assets/img/1x/UPS.png";
import {Link, NavLink, useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AddItemOrders, GETOrders, GetProduct, GetSubmitItemOrders} from "./api";
import {toast} from "react-toastify";

const Menu = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const data = useSelector(state => state);
    const [total, setTotal] = useState({ControlPanel: 0, UninterruptiblePowerSupply: 0})
    const [products, setProducts] = useState([])
    const [information, setInformation] = useState([])
    const error = (e) => toast.error(e);
    const back = () => {
        if (window.innerWidth < 950) {
            document.querySelector('.dark-menu').classList.toggle('d-n')
            document.querySelector('#menu').classList.toggle('opened')
            document.querySelector('#root').classList.toggle('no-scroll')
            document.querySelector('.main .right').classList.toggle('open')
            document.querySelector('#menu').setAttribute('aria-expanded', 'true')
        }

    }


    useEffect(() => {
        GetSubmitItemOrders().then((response) => {
            if (response.status === 200) {
                setInformation(!!response.data ? response.data : []);
                // if (!!response.data && !!response.data.orderItems) {
                //     let removed = response.data.orderItems.filter((v)=> v.isRemoved === true)
                //     if (!!removed) {
                //         removed.map((v, i)=> warning(v.product.title  + ' به علت اتمام موجودی از سبد خرید حذف شد. '))
                //     }
                // }

            }
        })
    }, [data.product, location.pathname])

    useEffect(() => {
        GetProduct().then((response) => {
            // console.log(response)
            setProducts(response.data)
        })
    }, [])

    const negative = (v) => {
        switch (v.type) {
            case 'ControlPanel':
                setTotal({
                    ...total,
                    ControlPanel: total.ControlPanel > 0 ? total.ControlPanel - 1 : total.ControlPanel
                });
                break;
            case 'UninterruptiblePowerSupply':
                setTotal({
                    ...total,
                    UninterruptiblePowerSupply: total.UninterruptiblePowerSupply > 0 ? total.UninterruptiblePowerSupply - 1 : total.UninterruptiblePowerSupply
                })
                break;
            default:
                return '';
        }
    }

    const plus = (v) => {
        switch (v.type) {
            case 'ControlPanel':
                setTotal({
                    ...total,
                    ControlPanel: total.ControlPanel > -1 ? total.ControlPanel + 1 : total.ControlPanel
                });
                break;
            case 'UninterruptiblePowerSupply':
                setTotal({
                    ...total,
                    UninterruptiblePowerSupply: total.UninterruptiblePowerSupply > -1 ? total.UninterruptiblePowerSupply + 1 : total.UninterruptiblePowerSupply
                })
                break;
            default:
                return '';
        }

        // setTotalDrive(totalDrive > -1 ? totalDrive + 1 : totalDrive)
    }

    return (
        <div className="right">

            <div className="menu-row">
                <a href="/">
                    <div className="logo">
                        <img width={100} height={42} src={Logo} alt=""/>
                        <div className="line-space"/>
                        <p className='mb-0'>ﭘﻠﺘﻔﻮرم ﺳﻔﺎرش ﻣﺤﺼﻮﻻت آﺳﺎﻧﺴﻮری</p>
                        <div className="line-space"/>
                    </div>
                </a>
                <div className="line-space"/>
                <Link to={'/dashboard/profile'} onClick={() => back()}>
                    <div className="profile d-flex">
                        <img width={30} height={40} src={profile} alt=""/>
                        <div className="line-space"/>
                        <div className="information">
                            <p className='mb-1'>
                                {(!!data.login && !!data.login.firstName) ? data.login.firstName + '   ' + data.login.lastName : ''}
                            </p>
                            <p className='fs-8 mb-1'>
                                {(!!data.login && !!data.login.nationalId) ? data.login.nationalId : ''}
                            </p>
                        </div>


                    </div>
                </Link>
                <div className="line-space"/>

                <NavLink to="/dashboard/allOrders" className="m-menu" activeClassName="selected" onClick={() => back()}>
                        <span>
                            <img src={IconMenu} alt=""/>
                        </span>
                    <span>سفارش ها</span>
                </NavLink>

                {/*<NavLink className="m-menu sub-menu" to="/dashboard/expectation" activeClassName="selected">*/}
                {/*         <span>*/}
                {/*            <img src={IconMenu} alt=""/>*/}
                {/*        </span>*/}
                {/*    <span>وضعیت در انتظار</span>*/}
                {/*</NavLink>*/}

                {/*<NavLink to="/dashboard/confirmation" className="m-menu sub-menu" activeClassName="selected">*/}
                {/*         <span>*/}
                {/*            <img src={IconMenu} alt=""/>*/}
                {/*        </span>*/}
                {/*    <span>ﺗﺎﯾﯿﺪ و ﭘﺮداﺧﺖ</span>*/}
                {/*</NavLink>*/}

                {/*<NavLink to="/dashboard/history" className="m-menu" activeClassName="selected">*/}
                {/*         <span>*/}
                {/*            <img src={IconMenu} alt=""/>*/}
                {/*        </span>*/}
                {/*    <span>ﺳﻔﺎرش ﻫﺎی ﻗﺒﻠﯽ</span>*/}
                {/*</NavLink>*/}

                <NavLink to="/dashboard/shoppingList" className="m-menu menu-buy" activeClassName="selected"
                         onClick={() => back()}>
                         <span>
                            <img src={IconBuy} alt=""/>
                           {/*<span className=''>{(!!information.orderItems && information.orderItems.length > 0) ? information.orderItems.filter((v) => v.isRemoved === false).length : ''}</span>*/}
                        </span>
                    <span>ﺳﺒﺪ ﺧﺮﯾﺪ</span>
                </NavLink>

            </div>
            {
                data.url &&
                <div className="buy">
                    {!!products && products.length > 0 ?
                        <>
                            {
                                products.map((v, i) => {
                                    return <div key={i} className="drive-product">
                                        <div className="right-buy">
                                            {
                                                i === 0 &&
                                                <>
                                                    <img width={45} height={45} src={Drive} alt=""/>
                                                    <div className="line-space"/>
                                                    <div className="line-space"/>

                                                </>
                                            }
                                            {
                                                i === 1 &&
                                                <>
                                                    <img width={40} height={50} src={Ups} alt=""/>

                                                </>
                                            }
                                        </div>
                                        <div className="left-buy">
                                            <span>{v.title}</span>
                                            <div className="counter-product">
                                <span>
                                    <img width={25} height={25} src={Positive} alt=""
                                         onClick={() => plus(v)}/>
                                </span>
                                                <span>
                                    {total[v.type]}
                                </span>
                                                <span>
                                    <img width={25} height={25} src={Negative} alt=""
                                         onClick={() => negative(v)}/>
                                </span>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            <div className="line-space"/>
                            <div className="bottom" onClick={() => {

                                if (!!products[0] && (total.ControlPanel > 0 || total.UninterruptiblePowerSupply > 0)) {

                                    let body = []
                                    if (total.ControlPanel > 0 && total.UninterruptiblePowerSupply > 0) {
                                        body = [
                                            {
                                                productId: products[0].id,
                                                units: total.ControlPanel
                                            },
                                            {
                                                productId: products[1].id,
                                                units: total.UninterruptiblePowerSupply
                                            }
                                        ]
                                    } else if (total.ControlPanel > 0) {
                                        body = [
                                            {
                                                productId: products[0].id,
                                                units: total.ControlPanel
                                            }
                                        ]
                                    } else if (total.UninterruptiblePowerSupply > 0) {
                                        body = [
                                            {
                                                productId: products[1].id,
                                                units: total.UninterruptiblePowerSupply
                                            }
                                        ]
                                    }
                                    // console.log(data.product)
                                    // if (data.login.nationalIdConfirmed) {
                                    AddItemOrders(body).then((response) => {
                                        if (response.status === 200) {
                                            setTotal({
                                                UninterruptiblePowerSupply: 0,
                                                ControlPanel: 0
                                            });

                                            GETOrders('?filters=status==drafted').then((response) => {
                                                if (response.status === 200) {
                                                    let count = response.data
                                                    if (!!data.product && !!data.product[0]?.orderItems) {
                                                        count[0].orderItems = [...data.product[0].orderItems.filter(v => v.product.type !== "UninterruptiblePowerSupply"), ...response.data[0].orderItems.filter(v => !data.product[0].orderItems.some(e => (e.id === v.id && e.product.type !== "UninterruptiblePowerSupply")))]
                                                    } else {
                                                        count[0].orderItems = [...response.data[0].orderItems]
                                                    }
                                                    dispatch({
                                                        type: 'product',
                                                        product: !!response.data ? count : []
                                                    })
                                                }
                                            })
                                        } else {
                                            error(response.validationResult.errors[0].errorMessage)
                                        }

                                    })
                                    // } else {
                                    //     history.push('/dashboard/profile')
                                    //     error('لطفا اطلاعات خود(کدملی) را تکمیل کنید')
                                    // }


                                    if (history.location.pathname !== '/dashboard/allOrders') {
                                        history.push('/dashboard/allOrders')
                                    }
                                }


                            }}>
                                <span onClick={() => back()}>افزودن به سفارش ها</span></div>
                        </>

                        :
                        <>
                            <img width={55} src={Spinner} alt=""/>
                        </>
                    }

                </div>
            }
        </div>

    );
}

export default Menu;

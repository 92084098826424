import React, {useState} from 'react';
import Finger from "../assets/img/1x/Asset 6.png";
import Home from "../assets/img/1x/Asset 7.png";
import {useHistory} from "react-router-dom";

const AddProject = () => {
    const history = useHistory();
    const [information, setInformation] = useState({})
    return (
        <div className="add-project project">
            <div className="list">
                <div className="list-right">
                    <img width={85} height={85} src={Home} alt=""/>
                    <div className="list-column">
                        {/*<p className='blue-color fs-5 fw-bolder'>پــروژه ساختمــانی داتیــس شمــاره </p>*/}
                        <div className="form-group box-input">
                            <input id="form_name" type="tel" name="build" className="form-control"
                                   onChange={(e) => setInformation({...information ,build: e.target.value})}
                                   placeholder="پروانه ساخت" required="required"
                                   data-error="پروانه ساخت ضروریست."/>
                        </div>

                            <div className="form-group box-input">
                                <input id="form_name" type="text" name="nameProject" className="form-control"
                                       onChange={(e) => setInformation({...information ,nameProject: e.target.value})}
                                       placeholder="نام پروژه" required="required"
                                       data-error="نام پروژه ضروریست."/>
                            </div>

                        <div className="form-group box-input">
                            <input id="form_name" type="text" name="nameProject" className="form-control"
                                   onChange={(e) => setInformation({...information ,floor: e.target.value})}
                                   placeholder="تعداد طبقات" required="required"
                                   data-error="تعداد طبقات ضروریست."/>
                        </div>
                    </div>
                </div>
                <div className="list-left">
                    <label htmlFor="meli" className='upload'>
                    <span>
                        آپلود پروانه ساخت
                    </span>
                        <input type="file" id='meli' accept="image/*" onChange={(e)=> console.log(e.target.files[0])} hidden/>
                    </label>
                    {/*<p>*/}
                    {/*    وضعیت :  بررسی مدارک*/}
                    {/*</p>*/}
                    {/*<div className="bottom"><span>مشاهده پروژه</span></div>*/}
                </div>
            </div>
            <div className="line-space"></div>
            <div className="line-space"></div>
            <div className="center">
                <div className="bottom bottom-w" onClick={()=> history.push('/dashboard/checkInformation')}><span>ثبت</span></div>
            </div>
        </div>
    );
};

export default AddProject;

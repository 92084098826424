import React, {useEffect, useRef, useState} from 'react';
import jsQR from 'jsqr';
import './style.css';
import frame from "../../assets/img/SVG/corner.svg";
import Delete from "../../assets/img/1x/delete.png";

// import ReloadIcon from "../../assets/img/refresh-button.svg";

let canvas;
let access = true;
let Video;
let CanvasElement;
let getTracks;
let permission = false;
let permissionIOS = true;
let iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
let Firefox = /firefox/i.test(navigator.userAgent);
const height = window.innerHeight
const width = window.innerWidth
const constraints = {audio: false, video: {facingMode: {exact: "environment"}}};

const Scanner = (props) => {
    const video = useRef();
    const canvasElement = useRef();
    const [camera, setCamera] = useState(Boolean);
    const [message, setMessage] = useState('دوربین در دسترس نمیباشد');
    const Device = () => {
        Video = video.current;
        CanvasElement = canvasElement.current;
        if (!!navigator.mediaDevices.enumerateDevices()) {
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                devices = devices.filter((d) => d.kind === 'videoinput');
                if (devices.length > 3) {
                    navigator.mediaDevices.getUserMedia({
                        audio: false,
                        video: {
                            // deviceId: devices[1].deviceId,
                            facingMode: {exact: "environment"}
                        }
                    }).then(gotMedia).catch((e) => {
                        if (iOS) {
                            window.location.reload()
                        }
                        permissionIOS = false;
                    });
                } else if (devices.length > 0) {
                    navigator.mediaDevices.getUserMedia(constraints)
                        .then(gotMedia)
                        .catch(e => {
                            console.error('getUserMedia() failed: ' + e);
                            // alert('0000')
                            if (iOS) {
                                window.location.reload()
                            }
                            permissionIOS = false;
                        });
                } else {
                    props.onError('no access')
                }
            })
        } else {
            navigator.mediaDevices.getUserMedia(constraints)
                .then(gotMedia)
                .catch(e => {
                    console.error('getUserMedia() failed: ' + e);
                    if (iOS) {
                        window.location.reload()
                    }
                    permissionIOS = false;
                });
        }
    }
    const startFunction = () => {
        if (!!navigator.permissions && !Firefox) {
            navigator.permissions.query({name: 'camera'}).then((result) => {
                if (result.state === 'granted') {
                    permission = true;
                    Device();

                } else if (result.state === 'prompt') {
                    // permission = true;
                    Device();
                } else if (result.state === 'denied') {
                    setCamera(false)
                    props.onError('error denied')
                    console.log('denied')
                    // Device();
                    if (!iOS) {
                        setMessage('دسترسی دوربین به حالت بلاک درامده است')
                    }
                }
                result.onchange = function () {
                    Device();
                };
            });
        } else if (!!navigator.mediaDevices) {
            Device()
        } else {
            props.onError('error')
        }
    }
    const gotMedia = async (stream) => {
        setCamera(true)
        Video = video.current;
        CanvasElement = canvasElement.current;
        if (!!Video && !!CanvasElement) {
            Video.srcObject = await stream;
            // await Video.pause();
            setTimeout(() => {
                Video.play();
                getTracks = Video.srcObject.getTracks();
            }, 0);
            Video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
            canvas = CanvasElement.getContext("2d");
            // requestAnimationFrame(Start);
            Start()
        }
    }
    const Start = () => {
        if (!!video && !!Video && video.readyState === video.HAVE_ENOUGH_DATA && access) {
            // CanvasElement.height = (Video.videoHeight > 100 ? Video.videoHeight : 220);
            // CanvasElement.width = (Video.videoWidth > 100 ? Video.videoHeight : 220);
            CanvasElement.height = height / 2.5;
            CanvasElement.width = width / 1.5;
            canvas.drawImage(Video, 0, 0, CanvasElement.width, CanvasElement.height);
            const imageData = canvas.getImageData(0, 0, CanvasElement.width, CanvasElement.height);
            try {
                const code = jsQR(imageData.data, imageData.width, imageData.height, {
                    inversionAttempts: "dontInvert",
                });
                if (code) {
                    props.onScan(code.data)
                    // alert(code.data)
                } else {
                    // props.onError('error')
                }
            } catch (error) {
                console.error(error);
                // expected output: ReferenceError: nonExistentFunction is not defined
                // Note - error messages will vary depending on browser
            }
            if (access) {
                requestAnimationFrame(Start);
            }
        }
    }
    useEffect(() => {
        startFunction();
    }, [])
    const stop = () => {
        if (!!Video && !!getTracks) {
            getTracks.forEach(async (track) => {
                await track.stop();
            });
        } else if (!!Video) {
            Video.pause();
        }
        // if (!!navigator.mediaDevices && permission){
        //     navigator.mediaDevices.getUserMedia({video: {facingMode: {exact: "environment"}}, audio: false})
        //         .then(async mediaStream => {
        //             if (!!mediaStream.getTracks()){
        //                await mediaStream.getTracks().forEach((track) => {
        //                     track.stop();
        //                 });
        //             }
        //         })
        // }
    }

    function handleVisibilityChange() {
        if (document.hidden && permission && !iOS) {
            access = false;
            stop();
        } else if (!iOS || !permissionIOS) {
            startFunction();
        } else if (iOS && !video && !Video && video.readyState !== video.HAVE_ENOUGH_DATA) {
            Device();
        }

    }

    useEffect(() => {
        if (!!document.addEventListener) {
            document.addEventListener("visibilitychange", handleVisibilityChange, false);
        }
        access = true;
        return () => {
            access = false;
            stop();
        }
    }, [])

    return (
        <section className='video'>
            {
                camera ?
                    <>

                        <canvas id='canvas' ref={canvasElement} hidden></canvas>
                        <img src={frame} className={'frame'} alt=""/>
                        <video id="video" ref={video}>
                            <source type="video/webm"/>
                            <p>Your browser does not support HTML5 video.</p>
                        </video>
                        <div className="cancel-camera">
                            <img width={20} src={Delete} alt="" onClick={()=> props.onError(false)}/>
                        </div>
                    </>
                    :
                    <div className="permission-denied" onClick={() => {
                        setCamera(true)
                        props.refresh(true)
                        startFunction()
                    }}>
                        {/*<p>{message}</p>*/}
                        {
                            <div>
                                <br/>
                                <br/>
                                {/*<img width={40} height={40} src={ReloadIcon} alt="reload Camera"/>*/}
                            </div>
                        }
                    </div>
            }

        </section>
    );
};
export default Scanner;
